// =============================================================================
//
//  Checkout Page Style
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Stergios Michopoulos <stergios@improove.se>
//
//==============================================================================

@import '../themes/default/checkout/checkout';

.checkout {
    .header {
        &-checkout {
            @include flexbox();
            @include justify-content(flex-start);
            position: relative;
            height: 6.4rem;
            background-color: $black;
        }

        &-main-container {
            @include flexbox();
            @include align-items(center);
            width: 100%;
            padding: 0;
        }

        &-back-to-shop {
            @include flexbox();
            @include align-items(center);
            @include get-font($size: 'xsmall');
            position: absolute;
            top: 50%;
            left: 1.9rem;
            color: $white;
            transform: translateY(-50%);
        }

        &-back-to-shop-icon {
            height: 14px;
        }

        &-back-to-shop-text {
            margin-left: $base-margin * 0.5;
        }

        &-logo-big {
            position: absolute;
            right: 1.9rem;
            width: auto;
            height: auto;
        }

        &-logo-image {
            display: block;
            width: auto;
            height: 3.5rem;
        }
    }

    .order-total-summary {
        p {
            margin: 0;
        }
    }

    .product-line-item {
        .icon-trash,
        .product-tile-remove {
            align-self: center;
            font-size: 1.4rem;
        }
    }

    .product-line-item-details {
        margin-right: auto;

        .icon-trash,
        .product-tile-remove {
            align-self: center;
            font-size: 1.4rem;
        }

        .line-item-name {
            @include get-font($size: 'small');
            padding-left: $base-padding;
            color: $black;
        }

        .line-item-brand,
        .line-item-included-products {
            @include get-font($size: 'xsmall');
            color: $greyish-brown-two;
        }
    }

    .price-adjusted {
        @include get-font($size: 'normal');
        color: $red;
        font-weight: $bold;
    }

    .price-non-adjusted {
        @include get-font($size: 'xsmall');
        color: $brown-grey;
        text-decoration: line-through;
    }

    .discount-code-container {
        width: 100%;
        padding: 0;

        .discount-code-form {
            display: none;
        }

        .promo-code-submit {
            margin: 0;
        }

        .promo-code-btn {
            @include get-font($size: 'small');
            width: 100%;
            padding: ($base-padding * 0.82) ($base-padding);
            margin: ($base-margin) 0 0 0;
        }

        &.active {
            flex: 0 0 100%;

            .discount-code-header {
                display: none;
            }

            .discount-code-form {
                display: block;
            }
        }

        input[type='text'] {
            @include get-font($size: 'small');
            flex: 0 0 19.1rem;
            padding: 1.5rem;
            border-bottom: 0.1rem solid $black;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        }

        .form-control-label {
            @include get-font('xsmall');
            display: block;
            padding: 0 0 0.5rem;
            color: $black;
        }

        .coupon-close-form {
            text-align: left;
        }
    }

    .coupon-close-form {
        span {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $black;
            }
        }
    }

    .coupon-line-item {
        position: relative;
        overflow: visible;
    }

    [data-checkout-step='totals'] {
        .coupons-actions {
            display: none;
        }
    }

    .coupon-badge-added {
        display: none;
    }

    .coupon-remove-coupon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-0.6rem);

        span {
            position: relative;
            visibility: hidden;

            &::after {
                content: '';
                position: absolute;
                top: -0.1rem;
                left: -0.8rem;
                width: 0;
                height: 0;
                border-top: 0.6rem solid transparent;
                border-bottom: 0.6rem solid transparent;
                border-left: 0.6rem solid $black;
                visibility: visible;
            }
        }
    }

    .order-total-summary {
        flex: 1;
    }

    .order-summary {
        margin-top: 2.5rem;
    }

    .checkout-cart-container {
        margin: 0 auto;
    }


    .cart-body {
        .product-summary-block {
            margin: 0;
        }
    }

    &-title-wrapper {
        width: 100%;
        max-width: 82.9rem;
        padding: ($base-padding * 4) 0 ($base-padding * 1.8) 0;
        margin: 0 auto;
        text-align: left;
    }

    &-container {
        width: 100%;
        max-width: 82.9rem;
        padding: 0;
        margin: 0 auto;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

        .valid-cart-error {
            display: block;
            padding: 0;
            margin-top: 2rem;
            background: $white;
            color: $red;
            font-weight: 500;
            line-height: 1.5;
            text-align: center;
            white-space: pre;

            .close {
                top: 0;
            }
        }
    }

    &-container-inner {
        width: 100%;
        max-width: 82.9rem;
        padding: 0;
        margin: 0 auto;
    }

    &-container-voyado {
        width: 100%;
        max-width: 82.9rem;
        padding: 0 ($base-padding * 2);
        margin: 0 auto;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    }

    .next-step-button {
        position: static;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }

    &-cart-container {
        width: 100%;
        max-width: 82.9rem;
        margin: 0 auto;
    }

    .error-message {
        margin-top: $base-margin * 3;

        &-text {
            color: $red;
            text-align: center;
        }
    }

    .unifaun-error-message {
        margin: -($base-margin * 1.5) 0 ($base-margin * 1.5) 0;

        &-text {
            @include get-font('xsmall');
            color: $red;
            text-align: left;
        }
    }

    &-step-block {
        position: relative;
        width: 100%;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

        &::before {
            content: '';
            position: absolute;
            top: -15px;
            right: 0;
            left: 11px;
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $black;
        }

        fieldset {
            max-width: 62.1rem;
            padding: 0;
            border: none;
            margin: 0 auto;
        }
    }

    &-step-number {
        @include get-font($size: 'xsmall-small');
        position: absolute;
        top: -7px;
        left: 26px;
        color: $red;
        font-family: $teko;
        transform: translateX(-50%);
    }

    .modal {
        position: fixed;
        z-index: 9;
        top: 30%;
        right: auto;
        bottom: auto;
        left: 50%;
        display: none;
        width: 100%;
        max-width: 37.5rem;
        height: auto;
        padding-right: 0 !important; // override an inline js style
        background: $white;
        outline: none;
        transform: translate(-50%, -50%);

        &-dialog {
            padding: $base-padding * 2;
            margin: 0;
        }

        &-header {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            @include align-items(baseline);
            padding: 0 0 $base-padding * 2 0;
            border: none;

            .medium-title {
                @include get-font('medium');
                flex: 1;
                text-align: center;
            }

            .close {
                order: 1;
                margin: 0;
            }
        }

        &-body {
            @include get-font('tiny');
            padding: 0 0 $base-padding  0;

            .coupon-to-remove,
            .product-to-remove {
                padding-bottom: $base-padding;
                text-align: center;
            }

            .product-to-remove {
                font-weight: $bolder;
            }
        }

        &-footer {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            padding: 0;
            border-top: 0;

            .cart-delete-confirmation-btn {
                min-width: 10rem;
                padding: ($base-padding * 1.5) 0;
                background-color: $dark-red;
                color: $white;

                &:hover {
                    background-color: $wine-red;
                    color: $white;
                }
            }

            .cart-cancel-button {
                min-width: 10rem;
                padding: ($base-padding * 1.5) 0;
                background-color: $black;
                color: $white;

                &:hover {
                    background-color: $pf-grey;
                    color: $white;
                }
            }
        }
    }

    .modal-content {
        border: none;
    }

    .payment {
        &-methods {
            display: none;
            margin-top: 5.1rem;

            fieldset {
                max-width: 61.4rem;
            }
        }

        &-options {
            .nav-item {
                @include flexbox();
                @include flex-wrap(wrap);
                width: 100%;
                align-items: center;
                padding: ($base-padding * 1.2) 0 ($base-padding * 1.2) ($base-padding);
                border-top: $checkout-border;
                margin: 0;

                &.active {
                    border-left: solid 4px $black;
                }
            }

            .label {
                width: 100%;
                padding: $base-padding 0 $base-padding $base-padding * 3.2;
                line-height: $base-padding * 2;

                &::before {
                    top: inherit;
                }

                &-no-padding {
                    padding: 0;
                }
            }

            .payment-option {
                margin-left: $base-margin;
            }

            .tab-pane {
                display: none;
                width: 100%;

                &.gift-certificate-content {
                    max-width: 40.2rem;
                    margin: 0 auto;
                }
            }

            .form-control-radio-description {
                flex-basis: 40%;
                color: $black;
                font-weight: $bold;
            }

            .form-control-radio-sub-description {
                flex-basis: 100%;
                font-weight: $bold;
            }

            .form-control-radio-image {
                flex-basis: 27%;
                margin-left: 3%;
            }

            .form-control-radio-price {
                @include get-font('small');
                flex-basis: 30%;
                font-weight: 500;
                text-align: right;
            }

            .form-control-radio-title {
                @include flexbox();
                @include flex-wrap(wrap);
                @include justify-content(space-between);
            }

            .form-control-radio-sub-title {
                margin-bottom: $base-margin;
            }
        }
    }

    .totals-submit-container {
        display: none;

        .card {
            padding: ($base-padding * 2.7) 0 ($base-padding * 4.1) ($base-padding * 2.2);
        }

        .end-lines {
            padding-right: $base-padding * 1.2;
        }

        .grand-total {
            font-weight: $bolder;
        }

        .grand-total-sum {
            @include get-font('medium-big');
        }

        .btn {
            @include get-font('medium');
            width: 100%;
            padding: ($base-padding * 1.8) 0;
            margin-top: $base-margin * 3.7;
            line-height: 24px;
        }
    }

    .footer {
        &-disclaimers {
            max-width: 61.5rem;
            padding-top: $base-padding * 5;
            margin: 0 auto;
            text-align: center;
        }

        &-checkout-legal {
            @include get-font($size: 'small');
        }

        &-checkout-support {
            @include get-font($size: 'small');
            padding-bottom: $base-padding * 5.5;

            h4 {
                margin: 0;
            }

            p {
                margin-bottom: $base-margin;
            }
        }
    }

    &-button {
        max-width: 61.4rem;
        padding-top: $base-padding * 2.8;
        margin: 0 auto;
    }

    .data-checkout-stage[data-checkout-stage] button.place-order {
        display: block;
        width: 100%;
    }

    .sub-total {
        @include get-font('xsmall');
        color: $black;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: $normal-weight;
    }

    .discount-total {
        @include get-font('xsmall');
        color: $red;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: $normal-weight;
    }

    .grand-total-sum {
        @include get-font('small');
        color: $black;
        font-weight: 500;
    }

    .order-discount-total,
    .order-lineitem-discount-total,
    .product-discount-total {
        color: $red;
    }

    button {
        &:disabled {
            opacity: 0.5;
        }
    }

    [data-checkout-stage='customerIdentificationContent'] {
        .checkout {
            &-step-customerIdentificationContent-content {
                display: block;
            }

            &-step-customerIdentificationContent-summary {
                display: none;
            }

            &-step-shippingaddress-content {
                display: block;
            }

            &-step-shippingaddress-summary {
                display: none;
            }
        }
    }

    [data-checkout-stage='shippingAddress'] {
        .checkout {
            &-step-shippingaddress-content {
                display: block;
            }

            &-step-shippingaddress-summary {
                display: none;
            }

            &-step-customerIdentificationContent-content {
                display: block;
            }

            &-step-customerIdentificationContent-summary {
                display: none;
            }
        }
    }

    [data-checkout-stage='shippingMethods'] {
        .checkout {
            &-step-shippingmethod-content {
                display: block;
            }

            &-step-shippingmethod-summary {
                display: none;
            }
        }
    }

    [data-checkout-stage='payment'],
    [data-checkout-stage='totals'] {
        .checkout {
            &-step-paymentmethod-content {
                display: block;
            }

            &-step-paymentmethod-summary {
                display: none;
            }

            &-paymentmethod-step-block {
                &::before {
                    background-color: $black;
                }
            }

            &-placeorder-step-block {
                display: block;
            }
        }
    }

    [data-summary-method-icon] {
        .unifaun-icon {
            max-width: 7.5rem;
            margin: $base-margin 0 0 0;
        }
    }

    &-step-content {
        display: none;
    }

    &-step-summary {
        position: relative;
        display: block;
        max-width: 62.1rem;
        padding-bottom: 1rem;
        margin: 0 auto;
    }

    &-step-customerIdentificationContent-summary,
    &-step-shippingmethod-summary {
        .checkout-step-summary-wrapper:last-child {
            text-align: left;
        }
    }

    &-step-summary-wrapper:last-child {
        text-align: right;
    }

    &-step-paymentmethod-summary {
        .checkout-step-row {
            @include flex-wrap(wrap);
        }

        .checkout-step-summary-wrapper {
            flex: 0 0 100%;
            margin: 0 auto $base-margin * 2;
            text-align: center;
        }

        .checkout-step-summary-wrapper:last-child {
            text-align: center;
        }

        .checkout-step-summary-content {
            margin-bottom: $base-margin;
        }
    }

    &-step-payment-summary {
        padding-bottom: 0;

        .checkout-step-summary-wrapper {
            flex: 0 0 50%;
        }

    }

    &-step-summary-gift-certificate-wrapper {
        display: none;
    }

    &-step-action {
        position: absolute;
        bottom: 0;
    }

    &-step-row {
        @include flexbox();
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    &-step-summary-title {
        @include get-font($size: 'xsmall');
        color: $greyish-brown;
        text-transform: uppercase;
    }

    &-step-summary-content {
        @include get-font($size: 'mini-xsmall');
        padding: $base-padding / 2 0;
        color: $brown-grey;
    }

    &-payment-options {
        padding: 0;
    }

    &-payment-description {
        @include get-font($size: 'small');
    }

    &-shippingaddress-step-block {
        margin-top: $base-margin * 5.1;
    }

    &-installment-detail-info {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        width: 100%;
        margin: 1rem 0 0;

        .checkout-step-summary-title {
            @include get-font('micro');
            @include flexbox();
            @include flex-wrap(wrap);
            flex: 0 0 33%;
            padding: $base-padding * 0.5;
            margin: 0.1rem 0 0;
            background: $very-light-pink;
            text-align: center;
            text-transform: none;

            .checkout-step-summary-title-head {
                flex: 0 0 100%;
            }

            .checkout-step-summary-title-val {
                flex: 0 0 100%;
                font-weight: 600;
            }
        }
    }

    // Voyado Style

    .voyado {
        &-widget-wrapper {
            position: relative;
        }

        &-widget-inner-wrapper {
            width: 100%;

            .form-group {
                margin-bottom: 0;
            }

            .voucher {
                width: 100%;
                padding: $base-padding;
                border: solid $border $klarna-checkout__border;
                margin-bottom: $base-margin * 0.5;
            }
        }

        &-loader-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            width: 100%;
            height: 100%;

            &.show {
                display: block;
            }
        }

        &-loader {
            position: relative;
            top: 35%;
            width: 3rem;
            height: 3rem;
            border: 0.5rem solid $brown-grey-four;
            border-radius: 50%;
            border-top: 0.5rem  solid $red;
            margin: auto;
            animation: spin 2s linear infinite;
        }

        &-voucher-left-until-use {
            display: block;
            padding: $base-padding 0;
            color: $red;
        }

        &-voucher-valid-to {
            padding-left: $base-padding * 2;
            line-height: 2rem;
        }

        &-voucher-error {
            padding: 0 $base-padding;
            color: $red;
        }

        &-vouchers-section {
            padding: ($base-padding * 1.5) 0;
            margin-top: $base-margin * 2;
        }

        &-title-wrapper {
            margin-bottom: $base-margin;
        }
    }

    .klarna-main {
        margin-top: $base-margin * 3;
    }

    &-slot-message {
        @include flexbox();
        @include get-font('xsmall-small');
        padding: $base-padding * 1.5;
        margin-top: $base-margin * 3;
        background-color: $light-blue;
    }

    &-promotion-empty {
        display: none;
    }
}

// Voucher Loader Animation

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.confirmation {
    &.page {
        padding-top: 0;
        background-color: $white;
    }

    .header {
        &-checkout {
            @include flexbox();
            @include justify-content(flex-start);
            position: relative;
            height: $confirmation-header__height;
            background: $confirmation-header__background;
        }

        &-main-container {
            @include flexbox();
            @include align-items(center);
            width: 100%;
            padding: $confirmation-header-container__padding;
        }

        &-back-to-shop {
            @include flexbox();
            @include align-items(center);
            @include get-font('xsmall');
            position: absolute;
            top: 50%;
            color: $confirmation-back-to-shop__color;
            transform: translateY(-50%);
        }

        &-back-to-shop-icon {
            line-height: 0.8;
        }

        &-back-to-shop-text {
            margin-left: $confirmation-back-to-shop-text__margin;
            font-family: $roboto-regular;
        }

        &-logo-big {
            height: $confirmation-header-logo__max-height;
            margin-right: 0;
            margin-left: auto;
            text-align: right;
        }

        &-logo-image {
            max-height: $confirmation-header-logo__max-height;
        }
    }

    .line-item-price-container {
        @include flexbox();
        @include justify-content(space-between);
        width: 70%;
    }

    .line-item-unit-price {
        @include get-font($size: 'medium-big');
        display: block;
        width: 9.3rem;
        font-family: $teko;
        text-align: left;
    }

    .price-adjusted {
        @include get-font($size: 'medium-big');
        color: $red;
        font-weight: $bold;
    }

    .price-non-adjusted {
        @include get-font($size: 'mini-xsmall');
        color: $brown-grey;
        text-decoration: line-through;
    }

    .leaf {
        max-height: 3.6rem;
    }

    .eko-message {
        @include get-font('mini');
        margin-bottom: 0;
        color: $brown-grey-three;
    }

    &-message {
        max-width: $confirmation-message__max-width;
        border-bottom: $checkout-border;
        margin: ($base-margin * 4.2) auto 0;
        text-align: left;

        &.klarna {
            border-bottom: 0;
        }

        .order-thank-you-msg {
            padding: $confirmation-thank-you-msg__padding;
        }

        .order-thank-you-email-msg {
            @include get-font('compact');
            max-width: $confirmation-thank-you-email-msg__max-width;
            padding: $confirmation-thank-you-email-msg__padding;
            margin: 0;
            color: $confirmation-thank-you-email-msg__color;
        }

        .order-thank-you-email-text {
            color: $confirmation-thank-you-email-text__color;
        }
    }

    &-summary {
        max-width: $confirmation-summary__max-width;
        padding-bottom: $confirmation-summary__padding-bottom;
        margin: 0 auto;

        .confirm-details {
            font-weight: $bolder;
        }

        .order-number {
            font-weight: $bolder;
        }
    }

    .card {
        &-body {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(flex-end);

            .product-summary {
                &-block {
                    width: 100%;
                    margin-bottom: 0;
                }

                &-header-block {
                    border-bottom: $checkout-border;
                }

                &-header-product {
                    display: none;
                    // stylelint-disable
                    &-title {
                        text-align: left;
                    }
                    // stylelint-enable
                }

                &-header-items {
                    display: none;
                    // stylelint-disable
                    .line-item-total-text {
                        // color: $confirmation-line-item-total-text__color;
                        text-align: left;
                        &.line-item-total-price-label {
                            text-align: right;
                        }
                    } // stylelint-enable
                }
            }

            span {
                &.qty-card-quantity-count {
                    @include get-font('medium-big');
                    width: $confirmation-card-body-span-qty__width;
                    font-family: $teko;
                }
            }
        }

        &.product-info {
            border-bottom: $confirmation-card-product-info__border-bottom;
        }

        &-empty {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(center);
            padding: $confirmation-card-empty__padding;
        }

        .remove-product {
            display: none !important;
        }

        .line-item-quantity {
            display: none;
        }

        .line-item-quantity-confirmation {
            @include get-font('small');
            display: block;
            margin-right: auto;
        }

        .order-line-item-quantity-info {
            @include flexbox();
            margin: 0;
        }
    }

    .product-line {
        &-item {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            position: relative;
            padding: $confirmation-product-line__padding;

            .product-card-footer {
                @include flexbox();
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                @include flex(0 0 100%);
                padding-left: $confirmation-product-line-footer__padding-left;
                margin-left: 0;
            }

            .align-items-start {
                @include flexbox();
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                @include flex(0 0 100%);
                padding-top: $confirmation-product-line-align-items-start__padding-top;
            }

            .quantity-icon {
                display: none;
            }
        }

        &-item-details {
            @include flexbox();
            @include flex-wrap(nowrap);
            @include flex(0 0 100%);

            .line-item-name {
                @include flex(0 0 100%);
                max-width: $confirmation-product-line-align-item-name__max-width;
                padding-left: $confirmation-product-line-align-item-name__padding-left;
                margin-left: 0;
            }

            .line-item-brand {
                @include get-font('xsmall');
            }

            .product-card-is-eligible-for-pledge {
                @include get-font('mini');
                color: $greyish-brown-two;
                font-weight: $normal-weight;
            }
        }

        &-image-container {
            width: $confirmation-product-line-image-container__width;
            height: $confirmation-product-line-image-container__height;

            .item-image {
                position: relative;
                width: 100%;
                height: 100%;
                padding-bottom: 100%;
            }

            img {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
            }
        }
    }

    .order-product-summary {
        margin-top: $base-margin * 2;
    }

    .address-summary {
        .address-summary-text {
            @include get-font('xsmall');
            font-weight: $normal-weight;
        }
    }

    .summary-section-label {
        font-weight: $bold;
    }

    .order-summary-phone,
    .shipping-phone {
        @include get-font('xsmall');
        font-weight: $normal-weight;
    }

    .line-item-total-price {
        .line-item-pricing-info {
            padding-top: 0;
        }

        .price {
            @include flexbox();
            @include align-items(center);
        }
    }

    .order-summary {
        padding-top: $confirmation-order-summary__padding-top;
    }

    .order-receipt-label {
        overflow: hidden;
        width: 13rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .order-end-lines {
        span {
            @include get-font('xsmall-small');
        }
    }

    .discount-code {
        @include get-font('xsmall');
        margin-left: $confirmation-discount-code__margin-left;
        color: $red;
    }

    .order-discount {
        .order-start-lines {
            @include flexbox();
            @include align-items(center);
        }
    }

    .order-total-summary {
        @include flexbox();
        @include flex-wrap(wrap);
        width: 100%;
        max-width: $confirmation-order-total-summary__max-width--large;
        margin-left: auto;

        .leading-lines {
            max-width: 100%;
        }

        .cart-total-container {
            width: $confirmation-cart-total-container__max-width;
        }

        .sub-total {
            @include get-font('xsmall-small');
            color: $black;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: normal;
        }

        .discount-total {
            @include get-font('xsmall-small');
            color: $red;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: normal;
        }

        .sub-total,
        .order-discount-total,
        .shipping-total-cost,
        .shipping-discount-total,
        .tax-total,
        .grand-total-sum,
        .product-discount-total {
            @include get-font('xsmall-small');
        }

        .subtotal-item,
        .order-discount,
        .shipping-item,
        .shipping-discount,
        .sales-tax-item,
        .grand-total,
        .discount-item {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(space-between);
            border-bottom: $checkout-border;
            font-weight: $normal-weight;
        }

        .coupon-line-item {
            padding: ($base-padding * 0.4) 0;
        }

        .coupons-and-promos {
            width: 100%;
        }

        .order-discount-total,
        .order-lineitem-discount-total,
        .product-discount-total {
            color: $red;
        }

        p {
            @include get-font('xsmall');
            margin: ($base-margin * 0.8) 0;
        }

        .sales-tax-item {
            border-bottom: none;
        }

        .grand-total {
            border-top: $confirmation-grand-total__border-top;
            font-weight: $bolder;
        }

        .order-discount {
            &.total {
                border-bottom: 0;
                font-weight: $bolder;
            }
        }
    }

    .checkout-registration {
        width: 100%;

        .form-group {
            margin-bottom: $confirmation-checkout-registration-form__margin;
        }

        .btn {
            @include get-font('medium');
            width: 100%;
            margin-top: $confirmation-checkout-registration-btn__margin;
        }

        .form-control {
            padding: $confirmation-create-account-input__padding;
            background-color: $white;
        }
    }

    .widget-article-grid {
        padding: 0;
    }

    .order-confirm {
        &-create-account-wrapper {
            display: none;
            width: 100%;
            padding-bottom: $confirmation-create-account-wrapper__padding-bottom;
            margin-bottom: $confirmation-create-account-wrapper__margin;
        }

        &-create-account {
            position: relative;
            width: 100%;
            max-width: $confirmation-create-account__max-width;
            padding: $confirmation-create-account__padding;
            margin: 0 auto;

            .card-header {
                margin-bottom: $confirmation-create-account-card-header__margin;
            }

            .card-subheader-custom {
                font-weight: normal;
            }

            .card-header-custom,
            .card-subheader-custom {
                @include get-font('small');
            }
        }

        &-slot {
            margin-top: $base-margin * 2;
        }

        &-button {
            position: relative;
            width: 100%;
            max-width: 50rem;
            padding: ($base-padding) 0;
            margin: 0 auto;

            .btn {
                width: 100%;
                margin-top: $base-margin * 0.5;
            }
        }

        &-tracking-button {
            background-color: transparent;
            color: inherit;
            text-decoration: none;
        }
    }

    .form-group .form-control-label {
        @include get-font('xsmall');
        display: block;
        margin-bottom: $confirmation-form-label__margin-bottom;
    }

    .account-content {
        .shipping-phone,
        .order-summary-phone,
        .order-summary-email {
            display: none;
        }
    }

    &-become-member-wrapper,
    &-become-member-wrapper-header {
        @include get-font('xsmall-small');
        max-width: 80rem;
        text-align: left;

        ul {
            padding: 0;
            list-style-position: inside;
        }
    }

    &-create-account-button-wrapper {
        margin: ($base-margin * 2) 0;

        .login-create-account-button {
            width: 100%;
            padding: $base-padding * 2 0;
            margin-bottom: $base-margin;
        }
    }

    .checkout-terms {
        .form-control-checkbox-title,
        a {
            @include get-font('xsmall');
        }
    }

    .modal {
        &-header {
            &.become-member {
                border: 0;

                .become-member-title {
                    @include get-font('medium-big');
                    margin-top: $base-margin * 3;
                }
            }

            .close {
                position: absolute;
                right: 1rem;
                background: transparent;
                color: $black;
            }
        }

        &.show {
            .modal-dialog {
                transform: translate(0, 50%);
            }
        }

        &-dialog,
        &-content {
            z-index: 1050;
        }

        .become-member-body {
            text-align: center;
        }

        .become-member-error-msg {
            color: $red;
        }
    }

    .price-ref {
        display: none;
    }
}

.confirmation-steps {
    max-width: $confirmation-steps__max-width;
    padding-bottom: $confirmation-steps__padding-bottom;
    margin: 0 auto;

    &-content {
        position: relative;
        width: 100%;
        padding: $confirmation-steps-content__padding;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -$confirmation-steps-content-before__left;
            width: calc(100% + #{$confirmation-steps-content-before__left * 2});
            height: 100%;
            background-color: $confirmation-steps-content__background-color;
        }
    }

    &-content-header {
        border-bottom: none;
    }

    &-content-header-text {
        @include get-font('xsmall');
        color: $brown-grey;
    }

    &-content-header-title {
        margin-bottom: $confirmation-steps-content-header__margin;
        color: $black-two;
    }

    &-content-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &-content-body {
        margin: $confirmation-steps-content-body__margin;
    }

    &-content-body-text,
    &-content-list-item {
        @include get-font('small');
    }

    &-content-body-text {
        margin-bottom: $confirmation-steps-content-body-text__margin;
    }

    .first-item {
        &::before {
            content: '1';
            position: absolute;
        }
    }

    .second-item {
        &::before {
            content: '2';
            position: absolute;
        }
    }

    .third-item {
        &::before {
            content: '3';
            position: absolute;
        }
    }

    .fourth-item {
        &::before {
            content: '4';
            position: absolute;
        }
    }

    .fifth-item {
        &::before {
            content: '5';
            position: absolute;
        }
    }

    &-content-list-item {
        position: relative;
        margin: $confirmation-steps-content-list-item__margin;

        &::before {
            top: 0;
            right: 0;
            left: -4.5rem;
            display: inline-block;
            width: $confirmation-steps-content-list-item__width-height;
            height: $confirmation-steps-content-list-item__width-height;
            border-radius: 50%;
            background-color: $black;
            color: $red;
            font-family: $teko;
            font-size: $confirmation-steps-content-list-item__font-size;
            line-height: $confirmation-steps-content-list-item__line-height;
            text-align: center;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.gift-certificate {
    &-use-form {
        display: none;
    }

    &-information {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);

        &-content {
            @include flexbox();
            @include flex-wrap(wrap);
            @include get-font('mini-xsmall');
            flex: 1 0 33%;
            margin-bottom: $base-margin * 2;
        }

        &-content-title {
            flex: 1 0 100%;
            padding-bottom: $base-padding;
            font-weight: 500;
        }

        &-content-decription {
            flex: 1 0 100%;
        }

    }

    &-masked {
        @include get-font('tiny');
    }

    &-remove {
        margin: $base-margin * 2 0;
    }

    &-remove-action {
        @include get-font('xsmall');
    }

    &-used-data {
        display: none;
    }

    &-used-amount {
        @include get-font('xsmall');
        margin-right: $base-margin;
        color: $red;
        font-weight: 600;
    }

    &-change-amount {
        @include get-font('xsmall');
    }

    &-place-order {
        display: none;
    }

    &-first-step,
    &-second-step,
    &-third-step {
        border: 0;
    }

    &-first-step,
    &-second-step {
        margin-bottom: $base-margin;
    }

    &-first-step {
        padding: 0;
        border: 0;

        .gift-certificate-form-wrapper {
            display: none;

            &.active {
                display: block;
                width: auto;
                margin: 0;

                .form-control {
                    @include get-font('small');
                    width: 100%;
                    height: 6.1rem;
                    padding: $base-padding * 1.5;
                    border-color: $black;
                    border-radius: 0;
                    background-color: $white;
                    box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.5);
                }
            }

            .button-single-form {
                width: 100%;
                padding: ($base-padding * 0.82) $base-padding;
                margin-bottom: $base-margin * 1.8;
            }
        }

        .gift-certificate-field-wrapper {
            width: auto;
            margin: 0;
        }
    }

    &-second-step {
        display: none;
        margin-top: $base-margin * 6;
    }

    &-third-step {
        padding: 0;
        border: 0;

        &.active {
            margin-top: $base-margin * 3;
        }

        .gift-certificate-information {
            padding-top: $base-padding * 2;
        }

        .gift-certificate-information-content {
            padding-bottom: $base-padding * 2;
            margin: 0;

            &:last-of-type {
                padding-bottom: 0;
                border-top: $border solid $klarna-checkout__border;
            }
        }

        .button-single-form {
            width: 100%;
            padding: ($base-padding * 0.82) ($base-padding);
            margin-top: $base-margin * 2;
        }
    }

    &-wrapper {
        margin: ($base-margin * 5.6) 0 ($base-margin * 2);
    }

    &-content {
        max-width: 100%;
    }

    &-added {
        padding: $base-padding * 2;
        margin-top: $base-margin * 9;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

        .gift-certificate-information-content-title {
            @include get-font('small');
            font-weight: $bolder;
        }
    }


    &-field-wrapper {
        width: 30.5rem;
        margin: ($base-margin * 4) auto;

        .button-single-form {
            display: block;
            margin-left: auto;
        }

        .form-control {
            border-color: $klarna-checkout__border;
            border-radius: $border * 2;
        }
    }

    &-title {
        margin-bottom: $base-margin;
        color: $klarna-checkout-text__color;
    }
}

.checkout-klarna-title {
    @include get-font('small');
}

.order-details-wrapper {
    .check-order-page {
        display: none;
    }

    .summary-container {
        padding-bottom: $order-details-wrapper-summary-container__padding-bottom;
    }

    .summary-section-label {
        @include get-font('small');
        padding-bottom: $order-details-wrapper-summary-container-summary-section-label__padding-bottom;
        border-bottom: $order-details-wrapper-summary-container-summary-section-label__border-bottom;
        margin-bottom: $order-details-wrapper-summary-container-summary-section-label__margin-bottom;
        font-weight: $order-details-wrapper-summary-container-summary-section-label__font-weight;

        &.order-number-label {
            font-weight: $order-details-wrapper-summary-container-summary-section-order-number-label__font-weight;
        }
    }

    .address-summary {
        .address-summary-text {
            @include get-font('xsmall');
            line-height: $order-details-wrapper-summary-container-summary-section-address-summary-text__line-height;
        }
    }
}

@keyframes shimmer {
    from {
        transform: translateX(-33.3%);
    }

    to {
        transform: translateX(33.3%);
    }
}

.shimmer-overlay {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -100%;
        bottom: 0;
        left: -100%;
        animation: shimmer 1s infinite linear;
        background-image: linear-gradient(to right,
        rgba(#fff, 0) 33.3%,
        rgba(#fff, 0.8),
        rgba(#fff, 0) 66.6%);
    }
}

//stylelint-disable selector-max-id
#klarna-checkout-container {
    overflow: hidden !important; /* Fix klarna scrolling issue */
}

.line-item-quantity-confirmation {
    display: none;
}

@import '../lib/unifaun';
@import '../screens/medium/checkout/checkout';
@import '../screens/large/checkout/checkout';
