// =============================================================================
//
//  Checkout Medium
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Stergios Michopoulos <stergios@improove.se>
//
// =============================================================================

@media screen and (min-width: $tablet-width) {
    .checkout {
        &-step-number {
            top: -11px;
        }
    }
}

