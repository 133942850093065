// =============================================================================
// Bonus Products Modal Variables
//==============================================================================

$choose-bonus-product-modal-header-title-font-size: 2.4rem;
$choose-bonus-product-modal-product-name-font-size: 1.8rem;
$choose-bonus-product-modal-footer-summary-font-size: 1.8rem;

.choose-bonus-product-modal {
    width: 100%;
    max-width: none;

    &-dialog {
        max-width: 800px;
    }

    &-header {
        align-items: center;
        padding: $base-padding * 1.8;

        &-title {
            font-size: $choose-bonus-product-modal-header-title-font-size;
        }
    }

    &-body {
        @include flexbox();
        @include justify-content(space-between);
        flex-wrap: wrap;
        padding: $base-padding * 1.8;
        @media screen and (max-width: $tablet-width) {
            flex-direction: column;
        }

        .product-detail,
        .product-detail .row {
            @include flexbox();
            height: 100%;
            flex-direction: column;
            flex-grow: 1;
        }

        .attribute > label {
            display: block;
            margin-bottom: $base-margin * 0.9;
        }

        .bonus-quantity {
            margin-top: $base-margin * 1.2;

            > label {
                display: block;
                margin-bottom: $base-margin * 0.9;
            }
        }

        .bonus-product-item {
            flex: 0 0 22%;

            @media screen and (max-width: $tablet-width) {
                margin-bottom: $base-margin * 4;
            }

            @media screen and (max-width: $desktop-width) {
                flex: 0 0 30%;
            }

            .product-name {
                font-size: $choose-bonus-product-modal-product-name-font-size;
            }

            .primary-images {
                flex: 1;
            }

            .swatch {
                margin: $base-margin * 1.2 0;
            }

            .select-bonus-product {
                width: 100%;
                padding: $base-padding $base-padding * 3;
            }
        }

        .carousel-item {
            text-align: center;

            img {
                width: 100%;
                @media screen and (max-width: $tablet-width) {
                    width: 50%;
                }
            }
        }
    }

    &-footer {
        padding: $base-padding * 1.8;

        .selected-pid.row {
            display: flex;
            justify-content: center;
            text-align: center;

            .remove-selected-bonus-product {
                @include get-font('small');
                padding: $base-padding * 0.5 $base-padding * 1.3;
            }
        }

        .bonus-summary,
        .bonus-product-name {
            margin: 0  $base-margin * 1.5 $base-margin 0;
            font-size: $choose-bonus-product-modal-footer-summary-font-size;
        }

        .add-bonus-products {
            max-width: 50%;
            padding: $base-padding $base-padding * 3;
            @media screen and (max-width: $tablet-width) {
                width: 100%;
                max-width: none;
            }
        }
    }
}
