.klarna-upsell-listing-header,
.klarna-upsell-listing-footer,
.klarna-upsell-listing-confirmation,
.klarna-upsell-listing-exception,
.klarna-upsell-listing-timeout {
    font-family: 'Assistant', sans-serif !important;
}

.klarna-upsell-widget {
    .container {
        padding: 0;
    }

    .klarna-upsell {
        &-container {
            padding: 0 $base-padding * 1.5;
        }

        &-listing-header {
            width: 100%;
            max-width: 164.7rem;
            margin: ($base-margin * 4.5) auto;

            &-title {
                @include get-font('normal');
                padding: 4.5rem 0;
                margin: 0 auto;
                background: $very-light-pink;
            }

            strong {
                @include get-font('large-big');
            }

            p {
                @include get-font('small');
                font-weight: $normal-weight;
            }
        }

        &-container-wrapper {
            width: 100%;
            max-width: 164.7rem;
            border-top: $border * 10 solid $red;
            border-bottom: $border * 10 solid $red;
            background: $white;
        }

        &-listing.row {
            margin-right: -($base-margin * 3);
            margin-left: -($base-margin * 3);

            .embla {
                padding: 0;
            }

            .embla__slide {
                min-width: 60%;
                flex: 0 0 50%;
                padding: 0 ($base-padding * 1.5);

                &:last-child,
                &:first-child {
                    border: none;
                }
            }

            .embla__button {
                display: none;

                &:hover {
                    background: none;
                }
            }

            .embla__button__svg {
                fill: $black;
            }

            .product {
                width: 100%;
                border: none;
            }

            .product-tile {
                flex-flow: column nowrap;
                border: none;

                &-CTA {
                    align-items: flex-start;
                }
            }

            .product-tile-image {
                height: auto;
            }

            .product-tile-image__container-item {
                padding-bottom: 100%;
            }

            .product-tile-description {
                margin: 0;
            }

            .product-tile-image,
            .product-tile-image__container-item,
            .product-tile-image__container {
                width: 100%;
            }

            .product-tile-name {
                @include get-font('small');
                min-height: 5.5rem;
                margin-top: $base-padding;
                font-weight: $bold;
            }

            .price {
                @include get-font('small-compact');
                min-height: 6rem;
                align-self: flex-start;
                color: $black;
                font-weight: $bold;
            }

            .price-non-adjusted {
                @include get-font('mini');
                color: $brown-grey;
                text-decoration: line-through;
            }
        }

        &-listing-footer {
            @include get-font('small');
            max-width: none;
            padding: 1rem 0;
            font-weight: $normal-weight;
        }

        &-toolbar {
            padding: ($base-padding * 1.6);
            text-align: center;

            button {
                @include get-font('almost-big');
                width: 100%;
                padding: $button-klarna__padding--large;
                font-weight: $bold;
            }
        }

    }

    .form-group {
        height: auto;
        margin: 0;
    }

    label.form-control-label.klarna-add-btn {
        position: absolute;
        right: 0;
        bottom: 1rem;
        display: block;
        width: auto;
        min-width: 8.2rem;
        height: auto;
        min-height: 4.7rem;
        padding: $base-padding ($base-padding * 2);
        border: solid $border $black;
        text-align: center;

        &::before {
            display: none;
        }

        i.checkbox-icon {
            @include get-font('normal');
            display: block;
            color: $black;
            font-style: normal;

            &::before {
                font-size: 0;
            }
        }

    }

    .embla__dots {
        @include flexbox();
        @include justify-content(center);
        gap: $embla-dots__gap;
        margin-top: $embla-dots__margin-top;

        button {
            width: $embla-dots__width__height;
            height: $embla-dots__width__height;
            background: $embla-dots__background;
            padding: 0;
            border-radius: 50%;

            &.embla__dot--selected {
                background: $embla-dots-active__background;
            }
        }
    }

    /* stylelint-disable selector-max-compound-selectors, stylelint rule is blocking css override */
    input[type='checkbox']:checked + .form-control-label {
        .checkbox-icon {
            position: static;
            font-size: 0;

            &::before {
                @include get-font('compact');
            }
        }
    }
    /* stylelint-enalble selector-max-compound-selectors, stylelint rule is blocking css override */
}


//-----------------------------------------------------------------------------
// Screen Large
// -----------------------------------------------------------------------------
@import '../../screens/large/components/confirmation/klarnaUpsell';
