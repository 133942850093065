// =============================================================================
//
//  Collapsible Item
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/collapsibleItem';

.collapsible {
    border-top: $collapsible__border-top;
    border-bottom: $collapsible__border-bottom;
    margin: $collapsible__margin;

    // -------------------------------------------------------------------------
    //  Collapsible Item Header:
    // -------------------------------------------------------------------------

    &-header {
        cursor: pointer;
    }

    &-header > .title {
        @include get-font('compact');
        position: relative;
        display: block;
        padding: $collapsible-header-title__padding;
        color: $collapsible-header-title__color;
        font-weight: $bolder;
        text-decoration: none;

        &::after {
            content: '\73';
            position: absolute;
            top: 50%;
            right: 0;
            font-family: 'gymgrossisten-icons' !important;
            font-size: $collapsible-header-title-after__font-size;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal !important;
            font-variant: normal !important;
            font-weight: normal !important;
            line-height: $collapsible-header-title-after__line-height;
            speak: none;
            text-transform: none !important;
            transform: translateY(-50%);
        }
    }

    // -------------------------------------------------------------------------
    //  Collapsible Item Content:
    // -------------------------------------------------------------------------

    &-content {
        @include get-font('small');
        overflow: hidden;
        height: 0;
        line-height: $collapsible-content__line-height;
        transition: height 0.3s ease-in-out;
    }

    // -------------------------------------------------------------------------
    //  Collapsible Item Active:
    // -------------------------------------------------------------------------

    &.active {
        border-top-color: $very-light-pink;

        > .collapsible-header > .title {
            font-weight: $bolder;

            &::after {
                content: '\70';
            }
        }

        > .collapsible-content {
            height: auto;
            padding-bottom: $collapsible-content-active__padding-bottom;
        }
    }
}

.account-faq {
    h2 {
        margin-bottom: 1.5rem;
    }

    .collapsible {
        border-top: $border solid $black;
        border-bottom: $border solid $black;
    }

    .title {
        @include get-font('small');
        font-weight: 400;

        &::after {
            content: '';
        }

        &::before {
            content: '\62';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            font-weight: 700 !important;
        }
    }

    .collapsible-content {
        @include get-font('small');

        p,
        a {
            @include get-font('small');
        }

        a {
            font-style: italic;
        }

        p + p {
            margin-top: 1rem;
        }
    }

    .collapsible.active {
        .title {
            &::after {
                content: '';
            }

            &::before {
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }
}

@import '../screens/large/components/collapsibleItem';
