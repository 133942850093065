// =============================================================================
//
//  Detail Variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

$breadcrumb__margin-top: $base-margin * 0.9;
$breadcrumb-item__color: $brown-grey;

$product-name__margin-top: $base-margin;
$product-wishlist-wrapper__margin-top: $base-margin * 0.3;

$brand-link__margin: 0 ($base-margin * 0.6) 0 0;
$brand-link__color: $greyish-brown;
$brand-link__text-decoration-color: #979797;

$product-images__margin-top: $base-margin * 2;
$product-images__margin-bottom: $base-margin * 6;
$product-image__width: 98%;

$product-image-slick-dots__width: 100%;
$product-image-slick-dots__padding: ($base-padding * 2.3) 0;

$promotions__top: 0.4rem;
$promotions__position: absolute;
$promotions__z-index: 1;

$promotions-promotion__min-width: 7.7rem;
$promotions-promotion__padding: ($base-padding * 0.3) ($base-padding * 0.7);
$promotions-promotion__margin-top: $base-margin * 0.4;
$promotions-promotion-sale__background-color: $red;
$promotions-promotion-sale__color: $white;
$promotions-promotion__background-color: $black;
$promotions-promotion__color: $white;
$promotions-promotion-new__background-color: $light-blue;
$promotions-promotion-new__color: $white;

$promotions-promotion-span__padding: $base-padding * 0.5;
$promotions-promotion-span__margin-top: $base-margin * 0.5;

$product-rating__margin-top: $base-margin * 1.5;
$product-rating__margin-bottom: $base-margin * 2;

$prices-price-adjusted__color: $red;
$prices-price-adjusted__font-weight: $bold;

$prices-price-non-adjusted__color: #7d7d7d;

$prices-price-sales__font-weight: $bold;

$price-per-kg__margin: $base-margin 0;

$prices-price-ref-price-discount__color: $red;

$product-availability-msg-item-before__width: 1rem;
$product-availability-msg-item-before__height: 1rem;
$product-availability-msg-item-before__margin-right: $base-margin * 0.3;
$product-availability-msg-item-in-stock__color: $greyish-brown-two;
$product-availability-msg-item-in-stock-before__background-color: $blue;
$product-availability-msg-item-low-stock__color: $ocre;
$product-availability-msg-item-low-stock-before__background-color: $ocre;
$product-availability-msg-item-out-of-stock__color: $red;
$product-availability-msg-item-out-of-stock-before__background-color: $red;
$product-availability-msg-item__top: 1.5rem;

$variation-dropdown__margin-top: $base-margin;
$variation-dropdown-variation-item__padding: $base-padding ($base-padding * 1.5);
$variation-dropdown-variation-span-unselectable__color: $brown-grey;
$variation-dropdown-variations__color: $red;
$variation-dropdown-rectangle__background-color: $brown-grey;
$variation-dropdown__max-height: 25rem;

$quantity-and-add-to-cart__margin-top: $base-margin * 2;

$quantity__margin-right: $base-margin * 1.3;
$cart-and-ipay-add-to-cart__padding: $base-margin * 1.5;

$product-outofsstock-popup__background-color: $white;
$product-outofsstock-popup__box-shadow: 0 0 1.1rem 0 rgba(0, 0, 0, 0.23);
$product-outofsstock-popup-wrapper__padding: ($base-padding) ($base-padding * 1.8);
$product-outofsstock-popup-close__right: 1.8rem;
$product-outofsstock-popup-close__font-size: 1.6rem;
$product-outofsstock-popup-question__margin-right: $base-margin * 3;
$product-outofsstock-popup-email-form__margin-top: $base-margin * 1.8;
$product-outofsstock-popup-email-form__margin-bottom: $base-margin;
$product-outofsstock-popup-consent__margin: ($base-margin * 2.5) 0;

$product-outofstock-popup-submit__padding-top: 1.3rem;
$product-outofstock-popup-submit__padding-bottom: 1.3rem;
$product-outofstock-popup-submit__background: $red;
$product-outofstock-popup-submit__color: $white;

$product-outofstock__padding: ($base-padding * 1.3) ($base-padding * 1.7);
$product-outofstock__margin-top: $base-margin * 1.5;
$product-outofstock__background-color: $pale-liliac;
$product-outofstock-circle__width: 4rem;
$product-outofstock-circle__min-width: 4rem;
$product-outofstock-circle__height: 4rem;
$product-outofstock-circle__background: $white;
$product-outofstock-circle__color: $red;
$product-outofstock-circle__line-height: 4rem;
$product-outofstock-text__margin: 0 ($base-margin * 1.6);
$product-outofstock-text__font-weight: $bold;

$promotion-box__padding: ($base-padding * 1.3) ($base-padding * 1.7);
$promotion-box__margin: $base-margin * 2.5 0;
$promotion-box__background-color: $very-light-pink-two;
$promotion-box-circle__width: 4rem;
$promotion-box-circle__min-width: 4rem;
$promotion-box-circle__height: 4rem;
$promotion-box-circle__background: $red;
$promotion-box-circle__color: $white;
$promotion-box-circle__line-height: 4rem;
$promotion-box-text__margin: 0 ($base-margin * 1.6);
$promotion-box-text__font-weight: $bold;
$promotion-box-tiles__margin: ($base-margin * 1.5) 0;
$promotion-box-tiles__gap: 1rem;
$promotion-box-tile__border: 0.1rem solid $very-light-pink;
$promotion-box-tile-active__border: $border solid $black;
$promotion-box-tile__padding: 1.1rem;
$promotion-box-tile__max-width: 8.2rem;
$promotion-box-tile-text__margin: 0.5rem 0 0;
$promotion-box-tile-text__line-height: 1;

$product-usps__padding: $base-padding 0;
$product-usps__margin-top: $base-margin * 1.5;
$product-usps-item-not-last-child__padding-right: $base-padding * 1.6;
$product-usps-item-not-last-child__border-right: $border solid rgba(0, 0, 0, 0.5);
$product-usps-item-not-first-child__padding-left: $base-padding * 1.5;
$product-usps-item-icon__height: 2.1rem;
$product-usps-item-icon__font-size: 2.1rem;
$product-usps-text__margin-left: $base-margin;

$product-usps-item-img__max-width: 4.8rem;
$product-usps-item-img__max-height: 1.2rem;

$table__margin-top: $base-margin * 2.5;
$table-tr__border-top: $border solid $white;

$product-info-section__margin-top: $base-margin * 0.8;

$product-sku__color: $brown-grey;

$product-short-description__margin-top: $base-margin * 1.6;
$product-short-description__color: $greyish-brown-two;
$product-short-description-ul__padding-left: $base-padding * 1.8;
$product-short-description-ul-li__line-height: 1.85;
$product-short-description-ul-li-before__margin-top: $base-margin * -1.5;
$product-short-description-ul-li-before__margin-left: $base-margin * -1.6;
$product-short-description-ul-li-before__font-size: 3rem;


$brand-logo__max-width: 7rem;

$product-tabs__padding: ($base-margin * 4) 0 ($base-margin * 3.5) 0;
$product-tabs-content__margin-top: $base-margin * 3;
$product-tabs-item__padding: 0 $base-padding;
$product-tabs-first-item__padding-left: $base-padding * 1.8;

$product-tabs-item__color: $greyish-brown-two;
$product-tabs-item-active__color: $greyish-brown-two;

$hr-line-one__margin-top: $base-margin * 0.8;
$hr-line-one__border-top: $border solid $very-light-pink;
$hr-line-two__margin-top: $base-margin * 0.6;
$hr-line-three__margin-top: $base-margin * 1.8;
$hr-line-four__margin-top: $base-margin * 0.8;
$hr-line-four__border-top: $border solid rgba($black-two, 0.1);

$icon-nowishlist__margin-top: $base-margin * 0.5;


$product-promotion-boxes__height: 5rem;
$product-promotion-boxes__padding: $base-padding;
$product-promotion-boxes__margin-top: $base-margin * 1.5;
$product-promotion-boxes__background: $very-light-pink-two;
$product-promotion-boxes__color: $greyish-brown;

$product-promotion-boxes-promotions__position: relative;
$product-promotion-boxes-promotions__margin-right: $base-margin * 2;

$product-promotion-boxes-promotions-promotion__min-width: 7.5rem;
$product-promotion-boxes-promotions-promotion__padding: $base-padding * 0.7;
$product-promotion-boxes-promotions-promotion-after__border: $border * 8 solid transparent;
$product-promotion-boxes-promotions-promotion-after__border-top-color: $red;
$product-promotion-boxes-promotions-promotion-after__margin-bottom: -0.4rem;

$prices-and-availability__margin-top: $base-margin;

$product-outofstock-popup__top: 7rem;
$product-outofstock-popup__border: $border solid $black-two;

$product-outofstock-popup-email-submit__padding: $base-padding * 2.5 0;
$product-outofstock-popup-email-submit__color: $greyish-brown;

$back-in-stock__error__padding: $base-padding * 0.3 0;
$back-in-stock__error__color: $red;

$product-video-block-full__width: 100%;
$product-video-block-full__height: 100%;
$product-video-block-container__pading-top: 56.26%;  /* By using percentage, it will calculate the padding 
                                                to use based on the width of the element. 
                                                In our example, we want to keep the ratio of 56.26% (height 9 ÷ width 16) 
                                                because this is the default ratio for YouTube videos/ */

// =============================================================================
// Large Screen
//==============================================================================

$go-back__padding--large: $base-padding * 1.5 $base-padding * 0.5;
$go-back-icon__height--large: 1.2rem;
$go-back-icon__padding-right--large: $base-padding * 0.5;
$go-back-icon__position: relative;
$go-back-icon__top: 0.1rem;
$go-back-icon__font-size--large: 1.2rem;
$go-back-text__position: relative;
$go-back-text__top: 0.1rem;
$go-back__color: $brown-grey-three;

$go-back__color-large: $brown-grey;

$product-images__flex-basis--large: 47%;
$product-image__width--large: 100%;

$product-images-promotions-promotion__padding: $base-padding * 0.6 0;

$product-images-promotions-right-after__border-top-color: $black;
$product-images-promotions-new-right-after__border-top-color: $red;

$promotions__top--large: 2rem;
$promotions-promotion-span__padding--large: ($base-padding * 0.5) $base-padding;

$swatch__margin--large: ($base-margin * 1.4) ($base-margin * 1.4) 0 0;
$swatch__background-color--large: $white;
$swatch-image__height--large: 7.5rem;

$swatch-rectangle-active__height--large: 0.3rem;
$swatch-rectangle-active__background-color--large: $red;

$product-detail__flex-basis--large: 47%;

$product-name__margin-top--large: $base-margin;


$price-adjusted__margin-right--large: $base-margin;

$hr-line-one__margin-top--large: $base-margin;

$read-more__margin--large: ($base-margin * 0.6) 0;

$product-short-description-ul__padding-left--large: $base-padding * 1.8;
$product-short-description-ul-li__line-height--large: 1.63;
$product-short-description-ul-li-before__margin-top--large: $base-margin * -1.2;
$product-short-description-ul-li-before__margin-left--large: $base-margin * -1.9;

$hr-line-two__margin-top--large: $base-margin;

$product-outofstock-popup__padding--large: ($base-padding * 2) ($base-padding * 1.8);

$product-variations__margin-top--large: $base-margin;

$quantity-and-add-to-cart__margin-top--large: $base-margin * 2;

$cart-and-ipay-add-to-cart__padding--large: ($base-padding * 1.5);
$cart-and-ipay-add-to-cart__max-height--large: 6rem;

$promotion-box-circle__width--large: 4.5rem;
$promotion-box-circle__min-width--large: 4.5rem;
$promotion-box-circle__height--large: 4.5rem;
$promotion-box-circle__line-height--large: 4.5rem;

$product-outofstock-circle__width--large: 4.5rem;
$product-outofstock-circle__min-width--large: 4.5rem;
$product-outofstock-circle__height--large: 4.5rem;
$product-outofstock-circle__line-height--large: 4.5rem;

$product-usps-item-not-last-child__padding-right--large: $base-padding * 1.5;
$product-usps-item-not-first-child__padding-left--large: $base-padding * 1.5;

$product-usps-item-img__max-width--large: 8rem;
$product-usps-item-img__max-height--large: 2rem;

$hr-line-three__margin-top--large: $base-margin;

$columns__flex-basis--large: 47%;
$columns__padding-right--large: $base-padding * 5;
$columns-table__width--large: calc(100% + 5rem);
$columns__line-height--large: 1.4;


$product-promotion-boxes__height--large: 6rem;

$product-promotion-boxes-promotions-promotion__min-width--large: 13rem;
$product-promotion-boxes-promotions-promotion__padding--large: $base-padding * 0.5;

$product-images-promotions-right__top--large: 2rem;
$product-images-promotions-right__left--large: 2rem;

$product-info-section__padding-top--large: $base-padding;
$product-info-section__border-top--large: $border solid $very-light-pink;


$product-availability-msg-item-before__width--large: 1.3rem;
$product-availability-msg-item-before__height--large: 1.3rem;
$product-availability-msg-item-before__margin-right--large: $base-margin;

$p__line-height: 1.4;
