// =============================================================================
//
//  Tabs
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/tabs';

.tabs {

    // -------------------------------------------------------------------------
    //  Tabs Header:
    // -------------------------------------------------------------------------

    &-header {
        @include flexbox;
        position: relative;
        right: 50%;
        left: 50%;
        overflow: auto;
        width: 100vw;
        border-bottom: $tabs-header__border-bottom;
        margin: $tabs-header__margin;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-header > .item {
        @include get-font('xsmall-small');
        @include flex-shrink(0);
        padding: $tabs-header-item__padding;
        color: $tabs-header-item__color;
        cursor: pointer;

        &.active {
            color: $tabs-header-item-active__color;
            font-weight: $tabs-header-item-active__font-weight;
        }
    }

    &-header > .item > .rectangle {
        display: none;
    }

    &-header > .item.active > .rectangle {
        display: block;
        width: 100%;
        height: $tabs-header-item-active-rectangle__height;
        margin-top: $tabs-header-item-active-rectangle__margin;
        background-color: $tabs-header-item-active-rectangle__background-color;
    }

    // -------------------------------------------------------------------------
    //  Tabs Content:
    // -------------------------------------------------------------------------

    &-content > .item {
        display: none;

        &.active {
            display: block;
        }
    }
}

