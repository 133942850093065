// =============================================================================
//
//  Checkout Page Variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Stergios Michopoulos <stergios@improove.se>
//
//==============================================================================

//General
$header-checkout__height: 6.2rem;
$header-checkout__height--large: 6.4rem;

$checkout-border: $border solid rgba($black, 0.2);
$checkout-border_low_opacity: $border solid rgba($black, 0.1);
$checkout-border_input_grey: ($border * 3) solid $brown-grey;
$checkout-border_input_green: ($border * 3) solid $black;

$checkout-title-wrapper_margin-top: 3rem;

//Confirmation Page

$confirmation-header__height: 6.2rem;
$confirmation-back-to-shop__color: $white;
$confirmation-header__background: $black;
$confirmation-back-to-shop-text__margin: $base-margin * 0.5;
$confirmation-header-container__padding: 0 ($base-padding * 1.8);
$confirmation-header-logo__max-height: 2.5rem;
$confirmation-header-logo__max-height--large: 4.1rem;
$confirmation-thank-you-msg__padding: ($base-padding * 2.6) 0 ($base-padding * 0.8) 0;
$confirmation-thank-you-email-msg__padding: ($base-padding * 0.8) 0 ($base-padding * 1.5) 0;
$confirmation-thank-you-email-msg__color: $greyish-brown;
$confirmation-thank-you-email-text__color: $black;
$confirmation-thank-you-email-msg__padding--large: ($base-padding * 1.5) 0 ($base-padding * 5) 0;
$confirmation-message__max-width: 83.5rem;
$confirmation-thank-you-email-msg__max-width: 40.2rem;
$confirmation-summary__max-width: 83.5rem;
$confirmation-summary__padding-bottom: $base-padding * 3.7;
$confirmation-summary__padding_bottom--large: $base-padding * 10;
$confirmation-summary-confirm__margin: $base-margin * 1.2;
$confirmation-summary-confirm__margin--large: $base-margin * 2.5;
$confirmation-card-summary-header-items__width--large: 30.5rem;
$confirmation-card-product-summary-header-items__color--large: $brown-grey;
$confirmation-card-empty__padding: ($base-padding * 3) 0;
$confirmation-card-body-span__width--large: 8.3rem;
$confirmation-card-body-span-qty__width: 6rem;
$confirmation-card-body-span__padding--large: $base-padding * 0.5;
$confirmation-card-product-info__border-bottom: $border solid $very-light-pink;
$confirmation-product-line__padding: ($base-padding * 1.8) 0;
$confirmation-product-line__padding--large: ($base-padding * 1.1) 0;
$confirmation-product-line-align-items-start__max-width--large: 29.5rem;
$confirmation-product-line-align-items-start__padding-top: $base-padding * 1.1;
$confirmation-product-line-align-item-name__max-width: 25.3rem;
$confirmation-product-line-align-item-name__padding-left: $base-padding;
$confirmation-product-line-align-item-name__max-width--large: 29.4rem;
$confirmation-product-line-align-item-name__padding--large: $base-padding * 2.6;
$confirmation-product-line-image-container__width: 7.7rem;
$confirmation-product-line-image-container__height: 7.7rem;
$confirmation-product-line__height--large: 11.5rem;
$confirmation-product-line-footer__padding-left: calc(#{$confirmation-product-line-image-container__width} + #{$base-padding});
$confirmation-product-line-image-container__width--large: 8.2rem;
$confirmation-product-line-image-container__padding-bottom--large: 87%;
$confirmation-order-summary__padding-top: $base-padding * 2.7;
$confirmation-order-summary__padding-top--large: $base-padding * 2;
$confirmation-discount-code__margin-left: $base-margin * 0.5;
$confirmation-order-total-summary__max-width: 25.3rem;
$confirmation-order-total-summary__max-width--large: 29.5rem;
$confirmation-cart-total-container__max-width: 24.2rem;
$confirmation-checkout-registration-form__margin: $base-margin * 2.2;
$confirmation-checkout-registration-btn__margin: $base-margin * 0.5;
$confirmation-create-account-wrapper__padding: ($base-padding * 3.5) 0 ($base-margin * 4.5) 0;
$confirmation-create-account-wrapper__padding--large: ($base-padding * 6) 0 ($base-margin * 4) 0;
$confirmation-create-account-wrapper__margin: $base-margin;
$confirmation-create-account__max-width: 62.1rem;
$confirmation-create-account__padding: ($base-padding) 0;
$confirmation-create-account-card-header__margin: $base-margin * 2.5;
$confirmation-create-account-card-header__margin--large: $base-margin * 3.2;
$confirmation-steps__max-width: 82.8rem;
$confirmation-steps-content__padding: ($base-padding * 2.2) ($base-padding * 1.5) ($base-padding * 3.7);
$confirmation-steps-content__padding--large: ($base-padding * 4) ($base-padding * 6)  ($base-padding * 6);
$confirmation-steps-content-before__left: 1.2rem;
$confirmation-steps-content-header__margin: $base-margin * 2;
$confirmation-steps-content-header__margin-bottom--large: $base-margin * 3.6;
$confirmation-steps-content-body__margin: ($base-margin * 1.7) auto 0;
$confirmation-steps-content-body-text__margin: $base-margin * 1.7;
$confirmation-steps-content-list-item__margin: 0 0 ($base-margin * 2.8) ($base-margin * 4.5);
$confirmation-steps-content-list-item__font-size: 20px;
$confirmation-steps-content-list-item__line-height: 36px;
$confirmation-steps-content-list-item__width-height: 34px;
$confirmation-steps-content-list-item__line-height--large: 2.3rem;
$confirmation-steps-content-list-item-before__top--large: -0.5rem;
$confirmation-steps-content__background-color: $brown-grey-four;
$confirmation-steps__padding-bottom: $base-padding * 2;
$confirmation-steps__padding-bottom--large: $base-padding * 7;
$confirmation-grand-total__border-top: ($border * 2) solid $black;
$confirmation-page__background-color--large: $very-light-pink-two;
$confirmation-form-label__margin-bottom: $base-margin * 0.4;
$confirmation-create-account-wrapper__padding-bottom: $base-margin * 4;
$confirmation-create-account-wrapper__padding-bottom--large: $base-margin * 10;
$confirmation-create-account-input__padding: $base-padding ($base-padding * 1.8);
$confirmation-create-account-input__padding--large: ($base-padding * 1.65) ($base-padding * 1.8);
$confirmation-thank-you-msg__padding--large: ($base-padding * 4.2) 0 ($base-padding * 0.8) 0;

// Check Order Page

$order-details-wrapper-summary-container__padding-bottom: $base-padding;

$order-details-wrapper-summary-container-summary-section-label__padding-bottom: $base-padding * 0.5;
$order-details-wrapper-summary-container-summary-section-label__border-bottom: $border solid rgba(0, 0, 0, 0.1);
$order-details-wrapper-summary-container-summary-section-label__margin-bottom: $base-margin;
$order-details-wrapper-summary-container-summary-section-label__font-weight: $bold;
$order-details-wrapper-summary-container-summary-section-order-number-label__font-weight: 400;

// Check Order Page large
$order-details-wrapper-sconfirmation-summary__padding-bottom--large: $base-padding & 3.7;

$order-details-wrapper-summary-container-summary-section-address-summary-text__line-height: 1.5;

// Klarna Chekcout
$klarna-checkout__border: rgb(150, 147, 145);
$klarna-checkout-text__color: rgb(120, 117, 115);


