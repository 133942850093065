// =============================================================================
//
//  Icons
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

[data-icon]::before {
    content: attr(data-icon);
    font-family: 'gymgrossisten-icons' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 1;
    speak: none;
    text-transform: none !important;
}

[class^='icon-']::before,
[class*=' icon-']::before {
    font-family: 'gymgrossisten-icons' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 1;
    speak: none;
    text-transform: none !important;
}

.icon-arrow::before {
    content: '\61';
}

.icon-arrow-left-right::before {
    content: '\62';
}

.icon-point::before {
    content: '\62';
}

.icon-point-right::before {
    content: '\62';
}

.icon-bonus::before {
    content: '\63';
}

.icon-bonus-filled::before {
    content: '\64';
}

.icon-camera::before {
    content: '\65';
}

.icon-cart::before {
    content: '\66';
}

.icon-check::before {
    content: '\67';
}

.icon-close::before {
    content: '\68';
}

.icon-delivery::before {
    content: '\69';
}

.icon-delivery-filled::before {
    content: '\6a';
}

.icon-droparrow::before {
    content: '\6b';
}

.icon-facebook::before {
    content: '\6c';
}

.icon-filter::before {
    content: '\6d';
}

.icon-instagram::before {
    content: '\6e';
}

.icon-menu::before {
    content: '\6f';
}

.icon-minus::before {
    content: '\70';
}

.icon-notfound::before {
    content: '\71';
}

.icon-nowishlist::before {
    content: '\72';
}

.icon-plus::before {
    content: '\73';
}

.icon-price-tag::before {
    content: '\74';
}

.icon-price-tag-filled::before {
    content: '\75';
}

.icon-return::before {
    content: '\76';
}

.icon-return-filled::before {
    content: '\77';
}

.icon-search::before {
    content: '\78';
}

.icon-star::before {
    content: '\79';
}

.icon-thumb::before {
    content: '\7a';
}

.icon-trash::before {
    content: '\41';
}

.icon-user::before {
    content: '\42';
}

.icon-wishlist::before {
    content: '\72';
}

// -----------------------------------------------------------------------------
//  Icon Point Up:
// -----------------------------------------------------------------------------

.icon-point-up,
.icon-arrow-up {
    display: block;
    float: left;
    transform: rotate(90deg);
}

// -----------------------------------------------------------------------------
//  Icon Point Down:
// -----------------------------------------------------------------------------

.icon-point-down,
.icon-arrow-down {
    display: block;
    float: left;
    transform: rotate(-90deg);
}

// -----------------------------------------------------------------------------
//  Icon Point Right:
// -----------------------------------------------------------------------------

.icon-point-right,
.icon-arrow-right {
    display: block;
    float: left;
    transform: rotate(180deg);
}

// -----------------------------------------------------------------------------
//  Icon Point:
// -----------------------------------------------------------------------------

.icon-arrow-left-right,
.icon-arrow {
    &.up {
        display: block;
        float: left;
        transform: rotate(90deg);
    }

    &.down {
        display: block;
        float: left;
        transform: rotate(-90deg);
    }

    &.right {
        display: block;
        float: left;
        transform: rotate(180deg);
    }
}
