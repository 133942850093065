// =============================================================================
//
//  Header Menu Navigation
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

.menu {
    &-main-container {
        position: fixed;
        z-index: 100;
        top: $header-top__height;
        left: -100%;
        width: $header-elements-full__width;
        height: calc(100% - #{$header-top__height});
        padding: $header-elements-base__padding;
        background-color: $header-navigation__background-color;
        overflow-x: hidden;

        &.active {
            left: 0;
        }
    }

    &-scroll-placeholder {
        position: relative;
        top: -5rem;
    }

    &-main {
        @include flexbox();
        @include align-items(stretch);
        @include flex-direction(column);
        @include flex-wrap(wrap);

        a {
            @include flexbox();
            @include align-items(center);
            position: relative;
            color: $header-navigation-links__color;
            font-size: $header-navigation-link__font-size;
            text-decoration: none;

            .icon-arrow {
                color: $header-navigation-icon-arrow__color;

                &-right {
                    height: $header-navigation-link-icon-arrow-right__height;
                    margin-left: auto;
                }
            }

            &.hide-brands-container {
                display: none;
            }

            &.view-all-cat-link {
                font-weight: 500;
                
                span {
                    text-decoration: inherit;
                }
            }

            &.menu-parent-category {
                span {
                    text-decoration: inherit;
                }
            }
        }
    }

    &-category-list {
        position: relative;
        width: $header-elements-full__width;
    }

    &-category-level-1,
    &-category-level-2,
    &-category-level-3,
    &-pages-list {
        display: block;
        padding: 0;
        margin: 0;

        > li {
            list-style-type: none;

            a {
                padding: $header-elements-base__padding 0;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: calc(#{$header-elements-full__width} + #{$header-elements-base__padding});
                    height: $header-navigation-border__height;
                    background-color: $header-navigation__border-color-light;
                }
            }
        }
    }

    &-category-level-1 {
        > li {
            > a {
                font-weight: $header-navigation-first-categpry-level-link__font-weight;
            }
        }
    }

    &-level-1-container {
        position: absolute;
        left: 0;
        width: $header-elements-full__width;
        padding: 0;
        margin: 0;
        transition: $header-menu__transition;

        // stylelint-disable selector-class-pattern
        &.level-2-active {
            left: -100vw;
        }

        &.level-3-active {
            left: -200vw;
        }
    }

    &-level-1-wrapper {
        padding: 0;
    }

    &-level-1-content {
        margin: 0;
    }

    &-level-2-container {
        position: absolute;
        top: 0;
        left: 100vw;
        width: $header-elements-full__width;
        padding: 0;
        margin: 0;
        visibility: hidden;
        transition: 0.2s 0.3s;

        &:focus-visible {
            outline: none; // Fixes Chrome user-agent style which adds extra boxing wrap.
        }
    }

    &-level-3-container {
        position: absolute;
        top: 0;
        left: 100vw;
        width: $header-elements-full__width;
        padding: 0;
        margin: 0;
        transition: $header-menu__transition;
        visibility: hidden;
    }

    &-active-container {
        visibility: visible;
        transition-delay: 0s;
    }

    &-mobile-active {
        position: relative;
        overflow: hidden;
    }

    &-state-trigger {
        i {
            color: $header-main-menu-icons__color;

            &.icon-close {
                color: $header-navigation-close-icon__color;
                font-size: $header-navigation-icon-close__font-size;
            }
        }
    }

    &-state-trigger {
        text-transform: $header-navigation-state-trigger__text-transform;
    }

    &-actions {
        position: relative;
        padding-bottom: $header-navigation-action__padding-bottom;

        .menu-back-action {
            display: none;
            margin-top: $header-navigation-back-action__margin-top;
            color: $header-navigation-back-icon__color;
            font-size: $header-navigation-back-icon__font-size;

            .icon-arrow {
                color: $header-navigation-icon-arrow__color;
            }

            &.show-button {
                @include flexbox();
                @include align-items(center);
            }
        }
    }

    &-dropdown-labels {
        position: relative;
        padding: $header-elements-base__padding 0;
        color: $header-navigation-second-level-labels__color;
        font-size: $header-navigation-dropdown-labels__font-size;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(#{$header-elements-full__width} + #{$header-elements-base__padding});
            height: $header-navigation-border__height;
            background-color: $header-navigation__border-color-light;
        }
    }

    &-back-text {
        @include flex($header-navigation-back-text_flex);
        margin-left: $header-navigation-back-text__margin-left;
        line-height: $header-navigation-back-text__line-height;
    }

    &-pages-container {
        margin: $header-navigation-pages-container__margin;
    }

    &-dropdown-content {
        margin: $header-navigation-dropdown-content__margin;
    }

    &-content-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}
