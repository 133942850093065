// =============================================================================
//
//  Slick Carousel
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

@import '../themes/default/lib/slick';

.slick-slider {
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slick-product-carousel,
.slick-content-carousel,
.slick-blog-carousel {
    opacity: 0;
    transition: opacity 1s;
    visibility: hidden;
}

.slick-product-carousel.slick-initialized,
.slick-content-carousel.slick-initialized,
.slick-blog-carousel.slick-initialized {
    opacity: 1;
    visibility: visible;
}

.slick-product-carousel .slick-item,
.slick-content-carousel .slick-item,
.slick-blog-carousel .slick-item {
    visibility: hidden;
}

.slick-product-carousel .slick-item:first-child,
.slick-content-carousel .slick-item:first-child,
.slick-blog-carousel .slick-item:first-child {
    display: block;
    visibility: hidden;
}

.slick-product-carousel.slick-initialized .slick-item,
.slick-content-carousel.slick-initialized .slick-item,
.slick-blog-carousel.slick-initialized .slick-item {
    display: block;
    visibility: visible !important;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100%;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    @include flexbox();
    position: relative;
    top: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;

    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {

    display: none;
    height: inherit;
    min-height: 1px;
    float: left;

    [dir='rtl'] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    > div {
        height: 100%;

        > div {
            height: 100%;
        }
    }
}

.slick-arrow {
    position: absolute;
    z-index: 1;
    width: $slick-arrow__width;
    height: $slick-arrow__height;
    cursor: pointer;
    font-size: $slick-arrow__font-size;

    &::before {
        position: absolute;
        top: $slick-arrow-before__top;
        left: $slick-arrow-before__left;
    }

    &.slick-hidden {
        display: none;
    }

    &.icon-arrow-left-right.right {
        right: 0;
    }

    &.icon-point-right {
        right: 0;
    }
}

.slick-dots {
    @include flexbox();
    position: absolute;
    right: 0;
    bottom: 0;
    padding: $slick-dots__padding;
    margin: $slick-dots__margin;
    list-style-type: none;

    li {
        margin: $slick-dots-li__margin;
    }

    button {
        display: block;
        width: $slick-dots-button__width;
        height: $slick-dots-button__height;
        padding: 0;
        border: 0;
        background-color: $slick-dots-button__background-color;
        border-radius: 100%;
        text-indent: -999rem;
    }

    li.slick-active button {
        background-color: $slick-dots-li-active__background-color;
    }
}
