// =============================================================================
//
//  Typography Base
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

// -----------------------------------------------------------------------------
//  HTML and Body:
// -----------------------------------------------------------------------------

@media screen and (min-width: $desktop-width) {
    html {
        font-size: 10px;
    }

    // -----------------------------------------------------------------------------
    //  Links:
    // -----------------------------------------------------------------------------

    a {
        @include get-font('small');
    }

    // -----------------------------------------------------------------------------
    //  Headings:
    // -----------------------------------------------------------------------------

    h1 {
        @include get-font('gigantic');
        letter-spacing: $h1__letter-spacing--large;
        line-height: $h1__line-height--large;
    }

    h2 {
        @include get-font('enormous-gigantic');
        letter-spacing: $h2__letter-spacing--large;
        line-height: $h2__line-height--large;
    }

    h3 {
        @include get-font('large');
        letter-spacing: $h3__letter-spacing--large;
        line-height: $h3__line-height--large;
    }

    // -----------------------------------------------------------------------------
    //  Button:
    // -----------------------------------------------------------------------------

    button {
        @include get-font('medium');
        max-width: 100%;
        padding: $button__padding--large;
    }

    // -----------------------------------------------------------------------------
    //  Table:
    // -----------------------------------------------------------------------------

    table {
        thead {
            th {
                @include get-font('compact');
            }
        }

        tbody {
            td {
                @include get-font('compact');
            }
        }
    }
}
