// =============================================================================
//
//  Helpers
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

// -----------------------------------------------------------------------------
//  General:
// -----------------------------------------------------------------------------

.visually-hidden {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0);
}

input[type='text'].hidden-input {
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: 0;
    clip: rect(0 0 0 0);
    opacity: 0;
}

.overlay-search {
    position: fixed;
    z-index: 30;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0.3s, opacity 1s ease;


    &.hide {
        opacity: 0;
        transition: visibility 0.3s, opacity 1s ease;
        visibility: hidden;
    }
}

.hide {
    display: none;
}

// -----------------------------------------------------------------------------
//  Visibility:
// -----------------------------------------------------------------------------

@include media-screen-sm {
    .show-for-small,
    .hide-for-x-large,
    .hide-for-large,
    .hide-for-medium {
        display: block !important;
    }

    .show-for-small-flex,
    .hide-for-medium-flex,
    .hide-for-large-flex {
        display: none !important;
    }

    .show-for-large,
    .show-for-x-large,
    .show-for-medium,
    .hide-for-small {
        display: none !important;
    }

    .show-for-small-inline,
    .hide-for-large-inline,
    .hide-for-medium-inline {
        display: inline-block !important;
    }

    .show-for-large-inline,
    .show-for-medium-inline,
    .hide-for-small-inline {
        display: none !important;
    }
}

@include media-screen-md {
    .show-for-medium,
    .hide-for-large,
    .hide-for-x-large,
    .hide-for-small {
        display: block !important;
    }

    .show-for-medium-flex,
    .hide-for-small-flex,
    .hide-for-large-flex {
        display: none !important;
    }

    .show-for-large,
    .show-for-x-large,
    .show-for-small,
    .hide-for-medium {
        display: none !important;
    }

    .show-for-medium-inline,
    .hide-for-large-inline,
    .hide-for-small-inline {
        display: inline-block !important;
    }

    .show-for-large-inline,
    .show-for-small-inline,
    .hide-for-medium-inline {
        display: none !important;
    }
}

@include media-screen-lg {
    .show-for-large,
    .hide-for-medium,
    .hide-for-small {
        display: block !important;
    }

    .show-for-large-flex,
    .hide-for-small,
    .hide-for-medium {
        display: flex !important;
    }

    .show-for-medium,
    .show-for-small,
    .hide-for-large {
        display: none !important;
    }

    .show-for-large-inline,
    .hide-for-medium-inline,
    .hide-for-small-inline {
        display: inline-block !important;
    }

    .show-for-medium-inline,
    .show-for-small-inline,
    .hide-for-large-inline {
        display: none !important;
    }
}

@include media-screen-xl {
    .hide-for-x-large {
        display: none !important;
    }

    .show-for-x-large {
        display: block !important;
    }
}

// -----------------------------------------------------------------------------
//  Clearfix:
// -----------------------------------------------------------------------------

.group,
%group,
.clearfix {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

.clear {
    clear: both;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.reset-border {
    border: none !important;
}

.hidden {
    display: none !important;
}

.no-styling-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.overflow-hidden {
    overflow: hidden;
}

.body-fixed {
    position: fixed;
    height: 100vh;
    width: 100vw;
}

.bold {
    font-weight: $bolder;
}

.relative {
    position: relative;
}

.text-center {
    text-align: center;
}

