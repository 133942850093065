// =============================================================================
//
//  Product Tile
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/productTiles';

.product {
    border-top: $product-tile__border-top;
    margin-bottom: $base-margin;

    &-item {
        height: 100%;
    }
}

.product-tile {
    @include flexbox();
    @include justify-content(space-between);
    position: relative;
    padding: $product-tile__padding;

    &-image__container {
        width: $product-tile-image__container__width;
        text-align: center;
    }

    &-image__container-item {
        width: $product-tile-image__container__width;
        height: $product-tile-image__container__height;
        background-color: $product-tile-image__container__background-color;
    }

    &-image {
        width: $product-tile-image__width;
        min-width: $product-tile-image__min-width;
        height: $product-tile-image__height;
        text-indent: -9999px; // fix the problem with alt text when image is not loaded
    }

    &-description,
    &-CTA {
        padding-top: $product-tile-description-cta_padding-top;
    }

    &-description {
        @include flexbox();
        @include flex-flow(column nowrap);
        @include flex-grow(1);
        position: relative;
        margin: $product-tile-description__margin;

        &-top {
            padding-right: $base-padding * 2.5;
        }

        .promotion-wrapper {
            margin-bottom: $product-tile-promotions__margin-bottom;
        }

        .promotions-right {
            display: inline-block;
            border-right: $product-tile-promotions-right__border-right;
            margin-left: $product-tile-promotions-new__margin-left;

            &:last-child {
                border-right: 0;
            }

            &:first-of-type {
                padding-right: $product-tile-promotions-new__padding-right;
                margin-left: 0;
            }

            .promotion {
                @include get-font('mini');
                display: inline-block;
                min-width: auto;
                padding: $product-tile-promotions-span__padding !important;
                /* to overwrite !important for padding that is set for general .promotion class */
                margin-right: $product-tile-promotions-span__margin-right;
                background: none;
                color: $product-tile-promotion-mobile__color;
                font-weight: normal;
                text-transform: none;

                &.badge--priceworthy {
                    background: $wine-red;
                    color: $white;
                }
            }
        }
    }

    .link {
        text-decoration: none;
    }

    &-name {
        @include get-font('small');
        margin: 0;
        font-weight: $normal-weight;
        word-break: break-word;
    }

    &-brand {
        @include get-font('mini');
        margin-top: auto;
        color: $product-tile-brand__color;
        text-decoration: none;
    }

    &-rating {
        width: 9rem;
        margin-top: $product-tile-rating__margin-top;

        .gmf-rating {
            margin-left: -0.4 * $base-margin;
        }
    }

    &-CTA {
        @include flexbox();
        @include justify-content(space-between);
        @include flex-flow(column nowrap);
        @include align-items(flex-end);
        position: relative;

        .price {
            @include flexbox;
            @include flex-flow(column nowrap);
            align-items: baseline;
            font-weight: 700;
            text-align: right;
            white-space: nowrap;

            .price-adjusted {
                @include get-font('normal');
                color: $product-tile-cta-price-adjusted__color;
                line-height: 1;
            }

            .price-non-adjusted {
                @include get-font('mini');
                width: 100%;
                color: $product-tile-cta-price-non-adjusted__color;
                line-height: 0.6;
                text-decoration: line-through;
            }

            .price-sales {
                @include get-font('normal');
                line-height: 1;
            }

            .price-ref {
                display: none;
            }
        }

        .price-ref {
            @include get-font('mini');
            @include flexbox();
            width: 100%;
            margin-bottom: $price-ref__margin-bottom;
            text-align: right;

            .price__value {
                white-space: nowrap;
            }
        }

        .product-is-eligible-for-pledge-wrapper {
            display: none;
        }

        .product-tile-buy {
            @include get-font('xsmall');
            padding: $product-tile-buy__padding;
            text-decoration: none;
        }
    }

    // Reference price block.
    .price-ref__stmt-container {
        @include flexbox;
        @include flex-flow(row nowrap);
        justify-content: space-between;

        .price__discount--percentage {
            // stylelint-disable-line selector-max-compound-selectors
            color: $prices-price-ref-price-discount__color;
            font-weight: $bold;
        }
    }

    .price-ref__stmt:nth-child(2) {
        padding-left: 1rem; // Minimum padding in case of too tight distance from flex.
    }

    .wishlist-wrapper {
        right: 8rem;
    }

    &-is-eligible-for-pledge-wrapper {
        margin-top: $base-margin * 0.3;
    }

    &-is-eligible-for-pledge {
        @include get-font('mini');
        color: $greyish-brown-two;
    }

    &-num-of-variants-wrapper {
        display: inline-block;
        padding: $base-padding * 0.4;
        margin: ($base-margin * 0.4) 0;
        background-color: $light-blue;
    }

    &-num-of-variants-wrapper-mobile {
        display: inline-block;
        padding: $base-padding * 0.4;
        margin: ($base-margin * 0.4) 0 0 0;
        background-color: $light-blue;
    }

    .quantity-callout {
        @include get-font('mini');
        text-wrap: nowrap;
    }

    .price-ref-wrapper {
        @include flexbox;
        @include flex-flow(column nowrap);
        @include align-items(baseline);
    }

    &-num-of-variants {
        @include get-font('tiny');
    }
}

.portrait-view {
    .slick-slider,
    .product-recommendation-grid {
        .product-tile {
            border: 0;
            padding-left: 0;
        }
        
        .promotion-wrapper {
            left: 0;
        }
    }
}

.promotion * {
    padding: 0;
    margin: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

.promotion-wrapper {
    .promotion {
        display: $product-tile-promotion-wrapper-promotion__display;
        padding-right: $product-tile-promotion-wrapper-promotion__padding-right;
        padding-left: $product-tile-promotion-wrapper-promotion__padding-left;

        &.badge--priceworthy {
            background: $wine-red;
            color: $white;
        }

        &.sale {
            background: $red;
            color: $white;
        }

        &.custom {
            background: $black;
            color: $white;
        }

        &.new {
            margin-left: 0;
            background: $light-blue;
            color: $white;
        }

        &:last-child {
            border: none;
        }
    }
}

@import '../components/productTilesMobileViews';