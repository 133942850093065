// =============================================================================
//
//  Dynamic Banner Variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$dynamic-banner__background-color: $light-blue;
$dynamic-banner__font-size: 1.6rem;
$dynamic-banner-content-wrapper__padding: ($base-padding * 1.05) ($base-padding * 1.2);
$dynamic-banner-content-wrapper__font-weight: $bold;
$dynamic-banner-content-wrapper__line-height: 1.31;
$dynamic-banner-close-banner-icon__position-right: 1.2rem;
$dynamic-banner-close-banner-icon__height: 2.1rem;
$dynamic-banner-text__position-top: 1.2rem;
$dynamic-banner-content-wrapper__max-width: 70%;
$dynamic-banner-icon-close__font-size: 2.1rem;

$dynamic-banner-content-wrapper__max-width--medium: 100%;
$dynamic-banner-content-wrapper__font-size--medium: 1.8rem;
$dynamic-banner-content-wrapper__padding--medium: ($base-padding * 1.6) ($base-padding * 10);
$dynamic-banner-content-wrapper__line-height--medium: 1.33;
$dynamic-banner-close-banner-icon__position-right--medium: 1.6rem;
