// =============================================================================
//
//  Unifaun Style Desktop
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Stergios Michopoulos <stergios@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .unifaun-checkout {
        &-option {
            &-price {
                @include flex(0 0 44%);
            }
        }
    }
}
