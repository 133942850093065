// =============================================================================
//
//  Checkout Desktop
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Stergios Michopoulos <stergios@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .checkout {
        .header {
            &-checkout {
                height: $header-checkout__height;
            }

            &-logo-big {
                position: relative;
                right: 0;
                margin: auto;
            }
        }

        &-step {
            width: 82.9rem;
        }

        &-step-block {
            position: relative;
            width: 100%;
            background-color: $white;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

            &::before {
                content: '';
                position: absolute;
                top: -2.2rem;
                right: 0;
                left: 2rem;
                display: inline-block;
                width: 4.4rem;
                height: 4.4rem;
                border-radius: 50%;
                background-color: $black;
            }

            fieldset {
                max-width: 62.1rem;
                padding: 0;
                border: none;
                margin: 0 auto;
            }
        }

        &-step-number {
            @include get-font($size: 'medium-big');
            position: absolute;
            top: -1.1rem;
            left: 4.2rem;
            color: $red;
            font-family: $teko;
            transform: translateX(-50%);
        }

        .modal-footer {
            .cart-delete-confirmation-btn {
                @include get-font('medium');
            }
        }

        .order-summary {
            @include flexbox();
            @include flex-wrap(nowrap);
        }

        [data-checkout-step='totals'] {
            .order-total-summary {
                flex: 0 0 100%;
            }
        }

        .order-total-summary {
            flex: 0 0 29.5rem;
        }

        .discount-code-container.active {
            max-width: 47rem;
        }

        .discount-code-container {
            padding: 0;

            &.active {
                .discount-code-form {
                    @include flexbox();
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }

            .form-control-label {
                flex: 0 0 100%;
            }

            .promo-code-btn {
                @include get-font($size: 'medium');
                padding: ($base-padding * 1.8) ($base-padding);
                margin: 0;
            }

            .coupon-close-form {
                text-align: right;
            }
        }

        &-container-voyado {
            padding: 0 ($base-padding * 5);
        }

        .voyado-vouchers-section {
            padding: ($base-padding * 4) 0;
        }
    }

    .confirmation {
        &.page {
            background-color: $confirmation-page__background-color--large;
        }

        .header {
            &-checkout {
                height: $header-checkout__height--large;
            }

            &-logo-big {
                height: $confirmation-header-logo__max-height--large;
                margin-right: auto;
                text-align: center;
            }

            &-logo-image {
                max-height: $confirmation-header-logo__max-height--large;
            }
        }

        .line-item-unit-price {
            @include get-font('normal');
            text-align: left;
        }

        .price-adjusted {
            @include get-font($size: 'medium-big');
            color: $red;
        }

        .price-non-adjusted {
            @include get-font($size: 'xsmall');
            color: $brown-grey;
            font-family: $roboto;
            text-decoration: line-through;
        }

        &-message {
            .order-thank-you-msg {
                padding: $confirmation-thank-you-msg__padding--large;
            }

            .order-thank-you-email-msg {
                @include get-font('medium');
                max-width: 100%;
                padding: $confirmation-thank-you-email-msg__padding--large;
            }
        }

        &-summary {
            padding-bottom: $confirmation-summary__padding_bottom--large;

            .confirm-details {
                display: block;
                margin-top: $confirmation-summary-confirm__margin--large;
            }
        }

        .card {
            &-body {
                margin-bottom: 0;

                .product-summary-header-block {
                    @include flexbox();
                }

                .product-summary-header-product {
                    @include flexbox();
                    color: $confirmation-card-product-summary-header-items__color--large;
                }

                .product-summary-header-items {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    @include justify-content(space-between);
                    width: $confirmation-card-summary-header-items__width--large;
                    margin-left: auto;
                    color: $confirmation-card-product-summary-header-items__color--large;
                    text-align: center;
                }

                .line-item-total-price-label {
                    text-align: right;
                }

                span {
                    @include get-font('xsmall');
                    width: $confirmation-card-body-span__width--large;
                    padding-bottom: $confirmation-card-body-span__padding--large;

                    &.qty-card-quantity-count {
                        @include get-font('normal');
                        font-family: $roboto-regular;
                        // stylelint-disable
                        &.order-track-qty {
                            text-align: left;
                        }
                        // stylelint-enable
                    }

                    &.unit-price-label {
                        text-align: right;
                    }
                }

                .order-number-label,
                .order-number {
                    @include get-font('large');
                    font-weight: $bolder;
                }
            }
        }

        .product-line {
            &-item {
                @include align-items(center);
                height: $confirmation-product-line__height--large;
                padding: $confirmation-product-line__padding--large;

                .remove-product {
                    @include get-font('xsmall');
                    position: absolute;
                    top: 4.4rem;
                    left: -2rem;
                }

                .product-card-footer {
                    @include flex(0);
                    max-width: none;
                }

                .align-items-start {
                    @include align-items(center);
                    width: $confirmation-product-line-align-items-start__max-width--large;
                    margin-left: auto;
                }

                .line-item {
                    &-price-container {
                        @include flexbox();
                        @include flex-wrap(nowrap);
                        @include flex(1);
                        @include justify-content(flex-end);
                        margin-left: 1rem;
                    }
            
                    &-total-price {
                        @include get-font('small');
                        @include justify-content(flex-end);
                        width: 10.3rem;
                    }
            
                    &-total-price-amount {
                        @include get-font('normal');
                    }
                }
            }

            &-item-details {
                @include flex-wrap(wrap);
                @include flex(0 0 50%);

                .line-item-name {
                    max-width: $confirmation-product-line-align-item-name__max-width--large;
                    padding-left: $confirmation-product-line-align-item-name__padding--large;
                    margin-left: 0;
                }
            }

            &-image-container {
                width: $confirmation-product-line-image-container__width--large;

                .item-image {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: $confirmation-product-line-image-container__padding-bottom--large;
                }

                img {
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }
        }

        .line-item-name span,
        .line-item-brand span,
        .line-item-included-products span {
            @include get-font('small');
        }

        .order-summary {
            padding-top: $confirmation-order-summary__padding-top--large;
        }

        .order-total-summary {
            .cart-total-container {
                width: $confirmation-order-total-summary__max-width--large;
            }
        }

        .order-receipt-label {
            width: 20rem;
        }

        .order-confirm {
            &-create-account {
                .card-header-custom,
                .card-subheader-custom {
                    @include get-font('large');
                }
            }

            &-create-account-wrapper {
                padding-bottom: $confirmation-create-account-wrapper__padding-bottom--large;
            }

            &-create-account {
                .card-header {
                    margin-bottom: $confirmation-create-account-card-header__margin--large;
                }
            }

            &-tracking-button {
                @include get-font('medium');
            }
        }

        .checkout-registration {
            .form-control {
                padding: $confirmation-create-account-input__padding--large;
            }
        }
    }

    .confirmation-steps {
        padding-bottom: $confirmation-steps__padding-bottom--large;

        &-content {
            padding: $confirmation-steps-content__padding--large;
            background-color: $confirmation-steps-content__background-color;

            &::before {
                display: none;
            }
        }

        &-content-body-text,
        &-content-list-item {
            @include get-font('small');
        }

        &-content-list-item {
            line-height: $confirmation-steps-content-list-item__line-height--large;

            &::before {
                top: $confirmation-steps-content-list-item-before__top--large;
            }
        }

        &-content-body {
            width: 86%;
            margin: 0;
        }

        &-content-header-title {
            @include get-font('enormous');
            margin-bottom: $confirmation-steps-content-header__margin-bottom--large;
        }
    }

    .gift-certificate {
        &-field-wrapper {
            .button-single-form {
                @include get-font('medium');
                width: 17.4rem;
                height: 6.1rem;
                margin: 0;
            }
        }

        &-first-step {
            .gift-certificate-form-wrapper {
                @include flexbox();
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                visibility: hidden;

                &.active {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    @include justify-content(space-between);
                    visibility: visible;

                    .form-control {
                        width: 19.1rem;
                    }
                }

                .button-single-form {
                    width: 17.4rem;
                    padding: ($base-padding * 0.74) $base-padding;
                    margin-bottom: 0;
                }

                .form-group {
                    max-width: 50%;
                }
            }

            .gift-certificate-field-wrapper {
                width: auto;
            }
        }

        &-second-step {
            width: 100vw;
        }

        &-third-step {
            &.active {
                .gift-certificate-added {
                    padding: $base-padding * 5;
                }
            }

            .button-single-form {
                @include get-font('medium');
                width: auto;
                padding: ($base-padding * 1.8) $base-padding;
            }
        }

        &-content {
            max-width: 47rem;

            .checkout-container-inner {
                width: 100vw;
            }
        }
    }

    .checkout-klarna-title {
        @include get-font('large');
    }
}

.order-details-wrapper {
    .confirmation-summary {
        padding-bottom: $order-details-wrapper-sconfirmation-summary__padding-bottom--large;
    }

    .check-order-page {
        display: none;
    }
}
