// =============================================================================
//
//  Unifaun
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Stergios Michopoulos <stergios@improove.se>
//
//==============================================================================

.checkout__unifaun-wrapper {
    input[type='checkbox'] {
        display: inline-block;
    }
}

.unifaun-checkout {
    &-option0 {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        max-width: 61.6rem;
        padding-top: ($base-padding * 1.4) !important;
        padding-bottom: ($base-padding * 0.6) !important;
        padding-left: $base-padding !important;
        border-top: $border solid rgba(0, 0, 0, .2) !important;
        border-right: none !important;
        border-bottom: none !important;
        border-left: none !important;
        margin: 0 auto !important;
        border-radius: 0;

        .unifaun-checkout-option-agents-wrapper {
            &::after {
                content: '\6b';
                font-family: 'gymgrossisten-icons' !important;
                font-size: 2rem;
                position: absolute;
                top: -0.5rem;
                right: 1rem;
                padding: ($base-padding * 0.5) 0 ($base-padding * 0.5) $base-padding;
                margin-top: $base-margin * 1.2;
                border-left: $border solid $brown-grey !important;
                z-index: 1;
            }
        }

        select {
            padding: $base-padding ($base-padding * 4) $base-padding $base-padding;
            border: $border solid $brown-grey !important;
            position: relative;
            z-index: 2;
        }
    }

    &-top-addons {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        max-width: 61.6rem;
        padding: ($base-padding * 1.4) 0 !important;
        border-top: 1px solid rgba(0, 0, 0, .2) !important;
        margin: 0 auto;
    }

    &-option-sub-options {
        border: none !important;
        background: $white !important;
    }

    &-option-header0 {
        @include flexbox();
        @include flex-wrap(wrap);
        width: 100%;
        padding-right: 0 !important;
        padding-left: 0 !important;
        border: none !important;
        margin-left: 0;

        &::before {
            content: '';
            display: inline-block;
            width: $input-radio-before__width;
            height: $input-radio-before__height;
            box-sizing: border-box;
            border: $input-radio-before__border;
            background-color: $input-radio-before__background-color;
            border-radius: 100%;
            color: $input-radio-before__color;
            line-height: $input-radio-before__line-height;
            text-align: center;
            vertical-align: middle;
        }

        label {
            @include get-font('small');
            color: $black;
            font-weight: $bold;
            text-transform: uppercase;
        }
    }

    &-option {
        &-radio {
            width: 1rem;
            order: 1;
        }

        &-prefix-icon {
            @include flex(0 0 50%);
            order: 2;
        }

        &-no-icons-spacer {
            width: 0;
        }

        &-columns {
            @include flex(0 0 100%);
            order: 4;
            padding-left: $base-padding * 3.7;
            margin-top: $base-margin * 0.5;
        }

        &-price {
            @include get-font('small');
            @include flex(0 0 40%);
            width: auto !important;
            font-weight: $bold;
            order: 3;
        }

        &-agent-info {
            @include get-font('xsmall');
            text-align: center;
            padding: $base-padding 0;
        }

        &-agent-opening-hours {
            margin-top: $base-margin;
        }
    }

    &-option-column1 {
        .image-exists {
            &::before {
                content: '';
                position: relative;
                display: block;
                width: 100%;
                height: 2rem;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    &-selected0 {
        border-left: solid 4px $black !important;

        .unifaun-checkout-option-header0 {

            &::before {
                border: $input-radio-checked-before__border;
                background-color: $input-radio-checked-before__background-color;
                box-shadow: $input-radio-checked-before__box-shadow;
            }
        }
    }
}

@import '../screens/large/lib/unifaun';
