// =============================================================================
//
//  All Brands Page Desktop
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .all-brands {
        &-box {
            &-letter {
                @include flex(0 0 10%);
                @include justify-content(center);
                margin-bottom: 0;
            }

            &-list {
                @include flex(0 0 90%);

                &-item {
                    @include flex(0 0 25%);
                }
            }
        }
    }
}
