// =============================================================================
//
//  Wishlist
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================
@import '../../themes/default/components/wishlist/wishlist';

.wishlist {
    &-wrapper {
        position: absolute;
        z-index: 1;
        top: $wishlist-wrapper-position__bottom;
        right: $wishlist-wrapper-position__right;
        cursor: pointer;
    }

    // General
    &-icon {
        font-size: $wishlist-icon__font-size;
        text-decoration: none;

        i {
            pointer-events: none;
        }

        &.inlist {
            color: $wishlist-inlist__color;

            .icon-nowishlist,
            .icon-wishlist {
                &::before {
                    content: '\43';
                }
            }
        }

    }

    &-count-badge {
        position: absolute;
        top: $wishlist-count-badge__top;
        right: $wishlist-count-badge__right;
        width: $wishlist-count-badge__size;
        height: $wishlist-count-badge__size;
        border-radius: $wishlist-count-badge__size;
        background: $wishlist-count-badge__background;
        color: $wishlist-count-badge__color;
        font-family: $teko;
        font-size: $wishlist-count-badge__font-size;
        font-weight: $bold;
        line-height: $wishlist-count-badge__line-height;
        text-align: center;
        transform: translateY(-#{$wishlist-count-badge__top});
    }

    > p {
        padding: 2.5rem 0;
    }
}

.card-view {
    .wishlist-wrapper {
        right: 0;
    }
}

.product-images.mobile {
    .wishlist-wrapper {
        top: auto;
        right: auto;
        bottom: $wishlist-wrapper__bottom;
        left: $wishlist-wrapper__left--large;
    }
}

.wishlistItemCards {
    .my-account-section-footer {
        width: 100%;
        grid-column: 1 / 3;

        button {
            width: 100%;
        }
    }
}
