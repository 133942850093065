// =============================================================================
//
//  Dynamic Banner
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

.dynamic-message-banner {
    margin-top: 0;
}

