// =============================================================================
//
//  Fonts
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

// -----------------------------------------------------------------------------
//  Icons:
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'gymgrossisten-icons';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/icons/gymgrossisten-icons.eot');
    src: url('../../fonts/icons/gymgrossisten-icons.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/icons/gymgrossisten-icons.woff') format('woff'),
        url('../../fonts/icons/gymgrossisten-icons.ttf') format('truetype'),
        url('../../fonts/icons/gymgrossisten-icons.svg#gymgrossisten-icons') format('svg');
    font-display: swap;
}
