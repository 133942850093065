// =============================================================================
//
//  Header Search
//
//  @version    0.0.1
//  @package    HSNG
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../themes/default/components/headerSearch';

.header-search {
    position: $header-search__position;
}

.clear-search {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    position: $clear-search__position;
    top: $clear-search__top;
    right: $clear-search__right;
    width: $clear-search__width;
    height: $clear-search__height;
    cursor: pointer;

    &.hide {
        display: none;
    }

    &-close {
        @include get-font('mini');
        height: $clear-search-close__height;
        font-size: $clear-search-close__font-size;
    }
}

.view-more-products {
    width: $view-more-products__width;
    padding: $view-more-products__padding;
    text-align: $view-more-products__text-align;

    &-link {
        @include get-font('xsmall');
        color: $view-more-products-link__color;
        font-weight: $view-more-products-link__font-weight;
        line-height: $view-more-products-link__line-height;
        text-decoration: $view-more-products-link__text-decoration;
        text-decoration-color: $view-more-products-link__text-decoration-underline-color;
    }
}

.header-search-dropdown {
    max-height: calc(100vh - 21.5rem);
    padding: $header-search-dropdown__padding;
    margin: $header-search-dropdown__margin;
    background: $header-search-dropdown__background;
    box-shadow: $header-search-dropdown__box-shadow;
    overflow-y: auto;

    .product {
        &-tile-CTA {
            .product-tile-buy {
                min-width: 9rem;
                text-align: center;
            }
        }
    }

    &.suggestions {
        margin-top: $base-margin * 0.8;
    }

    &-results {
        @include flexbox();
        @include flex-flow(column-reverse);
    }

    &-categories {
        @include flexbox();
        @include flex-flow(column);
        width: $header-search-dropdown-categories__width;

        &-results-list {
            padding: 0;
            margin: 0;
            list-style: none;

            &-li {
                margin-bottom: $header-search-dropdown-categories-results-list-li__margin-bottom;
                line-height: $header-search-dropdown-categories-results-list-li__line-height;

                > .link-item {
                    font-weight: $header-search-dropdown-categories-results-list-li-link__font-weight;
                    text-decoration: none;
                }

                &-link {
                    @include get-font('xsmall');
                    display: block;
                    margin-top: $base-margin;
                    color: $header-search-dropdown-categories-results-list-li-link__color;
                    font-weight: $header-search-dropdown-categories-results-list-li-link__font-weight;
                    text-decoration: none;
                }
            }
        }

        &-title {
            @include get-font('xsmall');
            margin-bottom: $header-search-dropdown-categories-title__margin-bottom;
            color: $header-search-dropdown-categories-title__color;
            font-weight: $header-search-dropdown-categories-title__font-weight;
        }
    }

    &-products {
        @include flexbox();
        @include flex-flow(column);
        width: $header-search-dropdown-products__width;

        @media screen and (max-width: $desktop-width) {
           .price-ref-wrapper {
                @include flex-flow(column);
                @include justify-content(space-between);
                height: 100%;
                padding-bottom: 8px;
            }

            .quantity-callout {
                margin-top:auto;
                margin-left: auto;
            }
        }

        &-wrapper {
            > .header-search-dropdown-product {
                .product {
                    border-top: none;
                    border-bottom: $header-search-dropdown-products-wrapper-product-tile__border-bottom;
                }
            }

            > .header-search-dropdown-product:first-of-type {
                .product {
                    border-top: $header-search-dropdown-products-wrapper-product-tile__border-bottom;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-title {
        @include get-font('xsmall');
        @include flexbox();
        margin-bottom: $header-search-dropdown-title__margin-bottom;
        color: $header-search-dropdown-title__color;

        .view-more-products-link {
            @include flexbox();
            display: none;
            margin-left: auto;
            line-height: 1;
        }
    }
}


.header-search-dropdown-categories-item {
    width: 100%;
    padding: $header-search-dropdown-categories-item__padding;
    border-top: $header-search-dropdown-categories-item__border-top;

    &:last-of-type {
        padding-bottom: 0;
    }

    &.first-category-item {
        padding-top: 0;
        border-top: none;
    }

    &.no-top-border {
        border-top: none;
    }
}

.header-search-dropdown-categories-results {
    &-paragraph {
        @include get-font('xsmall');
        margin: $header-search-dropdown-categories-results-paragraph__margin;

        > .results-link {
            @include get-font('xsmall');
            text-decoration: none;

            > .name {
                font-weight: $results-link__font-weight;
            }

            &.block {
                display: block;
            }
        }
    }


}

.header-search-dropdown-products-description {
    position: $header-search-dropdown-products-description__position;
    width: $header-search-dropdown-products-description__width;
    margin-left: $header-search-dropdown-products-description__margin-left;

    &-top-seller {
        @include get-font('mini');
        color: $header-search-dropdown-products-description-top-seller__color;

        > .rabat {
            margin-left: $rabat__margin-left;
            color: $rabat__color;
        }
    }

    .price {
        @include get-font('medium-big');
        position: $price__position;
        top: $price__top;
        right: $price__right;
        color: $price__color;
        font-weight: $price__font-weight;

        &-old {
            @include get-font('mini');
            display: block;
            color: $price-old__color;
            line-height: $price-old__line-height;
            text-align: $price-old__text-align;
            text-decoration: $price-old__text-decoration;
        }
    }

    .header-search-dropdown-products-description-title {
        @include get-font('xsmall-small');
        max-width: $header-search-dropdown-products-description-title__max-width;
        margin: $header-search-dropdown-products-description-title__margin;
        color: $header-search-dropdown-products-description-title__color;
        font-weight: $header-search-dropdown-products-description-title__font-weight;

        > .link {
            @include get-font('xsmall-small');
            text-decoration: none;
        }
    }

    .header-search-dropdown-products-description-category {
        @include get-font('mini');
        color: $header-search-dropdown-products-description-category__color;
    }

    .add-to-basket {
        position: $add-to-basket__position;
        right: $add-to-basket__right;
        bottom: $add-to-basket__bottom;
    }
}

.popular-search {
    transition: visibility 0.3s, opacity 0.3s ease;

    &.hide {
        position: absolute;
        z-index: -1;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s ease;
        visibility: hidden;
    }
}

.popular-search-dropdown {
    width: $popular-search-dropdown__width;

    &-links {
        padding-bottom: 0;
        
        &.recent {
            border-bottom: $popular-search-dropdown-links__border-bottom;
        }
    }

    &-title {
        @include get-font('xsmall');
        margin-bottom: $popular-search-dropdown-title__margin-bottom;
        color: $popular-search-dropdown-title__color;
    }

    &-list {
        @include flexbox();
        @include flex-wrap(wrap);
        padding: 0;
        margin: 0;
        list-style: none;

        &-li {
            @include flex(0 0 50%);
            padding-bottom: $popular-search-dropdown-list-li__padding-bottom;

            > .suggestion-item {
                @include get-font('xsmall');
                padding-bottom: $popular-search-dropdown-suggestion-item__padding-bottom;
                border-bottom: $popular-search-dropdown-suggestion-item__border-bottom;
                color: $popular-search-dropdown-suggestion-item__color;
                font-weight: $popular-search-dropdown-suggestion-item__font-weight;
                text-decoration: none;

                &:hover {
                    border-bottom: $border solid $red;
                    color: $red;
                }
            }
        }
    }

    &-help {
        padding-top: $popular-search-dropdown-help__padding-top;

        &-title {
            @include get-font('xsmall');
            margin-bottom: $popular-search-dropdown-help-title__margin-bottom;
            color: $popular-search-dropdown-help-title__color;
            font-weight: $popular-search-dropdown-help-title__font-weight;
        }

        &-list {
            padding: 0;
            margin: 0;
            list-style: none;

            &-li {
                > .suggestion-item {
                    @include get-font('small');
                    padding-bottom: $popular-search-dropdown-suggestion-item__padding-bottom;
                    color: $popular-search-dropdown-suggestion-item__color;
                    line-height: $popular-search-dropdown-suggestion-item__line-height;
                    text-decoration: none;
                    // stylelint-disable max-nesting-depth
                    &:hover {
                        border-bottom: $border solid $red;
                        color: $red;
                    }
                }
            }
        }
    }
}

.minicart {
    &-quantity {
        @include get-font('tiny');
        position: $minicart-quantity__positon;
        top: $minicart-quantity__top;
        left: $minicart-quantity__left;
        display: $minicart-quantity__display;
        width: $minicart-quantity__width;
        height: $minicart-quantity__height;
        border-radius: $minicart-quantity__border-radius;
        background-color: $minicart-quantity__background-color;
        color: $minicart-quantity__color;
        font-weight: $minicart-quantity__font-weight;
        line-height: $minicart-quantity__line-height;
        text-align: $minicart-quantity__text-align;
    }
}



