// =============================================================================
//
//  All Brands Page themes
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================


$all-brands__margin: $base-margin * 4 0;

$all-brands-title__margin-bottom: $base-margin;

$all-brands-letter-list__margin: $base-margin * 3 0;

$all-brands-letter-list-link__padding: 0 $base-padding * 0.2;

$all-brands-box__border-top: $border solid $pale-liliac;

$all-brands-box__border-top__padding: $base-padding * 1.5 0;
$all-brands-box__border-top__border-bottom: $border solid $pale-liliac;

$all-brands-box-letter__margin-bottom: $base-margin * 2;
$all-brands-box-letter__font-weight: bold;

$all-brands-box-list-item__padding: $base-padding * 0.5 0;

