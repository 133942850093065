// =============================================================================
//
//  Header Main Medium Large Screens
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width-x-large) and (max-width: $desktop-width-large) {
    .header {
        &-menu-container {
            .wrapper {
                padding: $header-navigation-second-level-wrapper__padding--medium-large;
            }
        }

        &.sticky {
            .header-container-search {
                width: $header-container-search-sticky__width--medium-large;
            }

            .header-search-dropdown {
                left: $sticky-header-header-search-dropdown__left--medium--large;
                width: $sticky-header-header-search-dropdown__width--medium--large;
            }
        }
    }
}
