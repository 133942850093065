// =============================================================================
//
//  Product Tile Mobile Views
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Bojan Brkic <bojan.brkic@impactcommerce.se>
//
// =============================================================================

@import '../themes/default/components/productTiles';

// Card View Mobile Layout
@media screen and (max-width: $desktop-width) {
    .card-view,
    .carousel-inner.slick-slider {
        .product-tile {
            @include flex-flow(column);
            height: 100%;

            &-image {
                width: 100%;
                height: auto;

                &__container {
                    position: relative;
                    width: 100%;
                    padding-top: 4.5rem;
    
                    &-item {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &-num-of-variants-wrapper-mobile {
                @include get-font('tiny');
                position: absolute;
                right: 0;
                bottom: $card-view-position__bottom;
            }

            &-description {
                position: static;
                margin: 0;

                .product-tile-rating,
                .gmf-rating-stars {
                    max-width: $card-view-gmf-stars__width;
                    white-space: nowrap;
                }
            }

            &-top-rating-variant {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(flex-start);
                order: -1;
                min-height: $card-view-rating__height;
                margin: $card-view-rating__margin;
            }

            &-CTA {
                @include flex-flow(row wrap);
                @include align-items(flex-start);
                padding-top: 2.5rem;
            }

            .price-ref {
                @include justify-content(flex-start);
                margin-bottom: 0;
                text-align: left;

                .price-ref__stmt-container,
                .price-ref__stmt {
                    width: 100%;
                }

                &-wrapper {
                    order: 1;
                    width: 100%;
                    min-height: $card-view-ref-wrapper__height;
                    margin-top: $price-ref__margin-top--large;
                }
            }

            &-promotions-right {
                position: absolute;
                top: 0;
                left: 0;

                .promotions-right {
                    @include flexbox;
                    @include flex-flow(column);
                    @include align-items(stretch);
                }

                .promotion {
                    &.sale {
                        min-width: $product-tile-promotion__min-width--large;
                    }
                }
            }

            &-num-of-variants-wrapper {
                &.show-for-large {
                    @include get-font('tiny');
                    display: block !important; /* !important is used since we override global template style */
                    order: 1;
                }
            }

            &-num-of-variants-wrapper-mobile {
                &.hide-for-large-inline {
                    display: none !important; /* !important is used since we override global template style */
                }
            }
        }
    }

    .carousel-inner.slick-slider {
        .product-tile {
            &-image__container {
                padding-top: 6.5rem;
            }

            &-promotions-right {
                top: 2rem;
                left: 1rem;
            }
        }
    }
}

// Portrait Mobile Layout
@media screen and (max-width: $tablet-width) {
    .portrait-view {
        .product {
            border: none;
            margin-bottom: $base-margin * 2;
        }
    
        .product-tile {
            @include flex-flow(column nowrap);
            position: relative;
            height: 100%;
            padding: 0 0 ($base-padding * 2);
    
            &-rating {
                height: 3.4rem;
                order: -1;
            }
            // portrait image style
            &-image__container {
                width: 100%;
                height: auto;
            }

            &-image__container-item {
                width: 100%;
                height: auto;
            }

            &-image-link {
                @include align-items(stretch);
                @include flex-direction(column);
                @include flex-shrink(0);
                position: relative;
                display: block;
                overflow: hidden;
                padding: 30% 0 100%;
            }
    
            &-image {
                position: absolute;
                top: 50%;
                right: 0;
                left: 50%;
                width: 130%;
                min-width: 100%;
                height: auto;
                margin: 0 auto;
                object-fit: cover;
                text-align: center;
                transform: translate(-50%, -50%);
                user-select: none;
            }
    
            &-description,
            &-CTA {
                padding-top: 0;
            }
    
            &-description {
                margin: ($base-margin * 0.3) 0 0 0;

                .wishlist-wrapper {
                    position: relative;
                    bottom: auto;
                }
            }
    
            &-description-top {
                @include flexbox();
                @include justify-content(space-between);
    
                .link {
                    width: calc(100% - 2rem);
                }
            }

            &-name {
                @include get-font('xsmall');
            }
    
            &-description-bottom {
                display: block;
            }
    
            &-CTA {
                @include flex-flow(row wrap);
                @include align-items(flex-start);
                margin-top: $base-margin * 0.6;
    
                .price {
                    text-align: left;
                }
            }
        }

        .product-siblings-wrapper {
            .product-tile-image-link {
                padding: 0 0 100%;
            }
        }

        .wishlist-wrapper {
            right: 1rem;
        }

        .carousel-inner.slick-slider {
            .product-tile {
                &-image {
                    width: 130%;
                }
    
                &-promotions-right {
                    left: 0
                }
            }
        }
    }
    
    @supports (-ms-ime-align: auto) {
        .product-tile {
            &-image__container {
                height: auto;
                padding-bottom: 0;
            }
        }
    }
}

// Override style for product tile in portrait view mobile

.portrait-view {
    .product-tile-top-rating-variant {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        order: -1;
        margin: $base-margin 0;
    }

    .product-tile-num-of-variants-wrapper-mobile {
        &.hide-for-large-inline {
            display: none !important; /* !important is used since we override global template style */
        }
    }


    .product-tile-num-of-variants-wrapper {
        order: 0;
        padding: ($base-padding * 0.2) ($base-padding * 0.4);
        margin: 0 0 auto;

        &.show-for-large {
            display: block !important; /* !important is used since we override global template style */
        }
    }

    .product-tile-rating {
        order: -1;
        margin: 0;
    }

    .product-tile-promotions-right.hide-for-large {
        display: none !important; /* !important is used since we override global template style */
    }

    .promotion-badge.show-for-large {
        display: block !important; /* !important is used since we override global template style */
    }

    .product-siblings-wrapper {
        .promotion-badge.show-for-large {
            display: none !important; /* !important is used since we override global template style */
        }
    }

    .product-tile-promotions-right {
        margin-bottom: 0;
    }

    .promotion-wrapper {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;

        @include media-screen-lg {
            left: 2rem;
        }
    }

    .product-tile--promotions-right {
        margin-bottom: 0;

        .promotions-right {
            z-index: 5;

            &.promotions-sale {
                top: $product-tile-promotions-sale__top--large;
            }
        }

        .promotion {
            @include get-font('tiny');
            min-width: $product-tile-promotion__min-width--large;
            padding: $product-tile-promotion__padding--large;
            margin: 0;

            &.sale {
                background: $red;
                color: $white;
            }

            &.custom {
                background: $black;
                color: $white;
            }

            &.new {
                margin-left: 0;
                background: $light-blue;
                color: $white;
            }

            &.badge--priceworthy {
                background: $wine-red;
                color: $white;
            }

            &:last-child {
                border: none;
            }
        }
    }

    .wishlist-wrapper {
        right: 1rem;
    }

    .price-ref-wrapper {
        width: 100%;
        min-height: $price-ref__min-height;
        margin-top: $price-ref__margin-top--large;
        order: 1;

        .price-ref {
            @include justify-content(flex-start);
            margin: 0;
        }
    }
}
