// =============================================================================
//
//  Product Recommendations
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../settings';
@import '../mixins/flex';
@import '../mixins/media-queries';
@import '../typography/mixins';
@import '../themes/default/components/productRecommendations';

.product-recommendation {
    margin: $product-recommendation__margin;

    &-calloutmsg {
        @include get-font('almost-big');
        font-weight: $product-recommendation-calloutmsg__font-weight;
        padding: 1.2rem 0 2.2rem;
    }

    .product {
        &:first-child {
            border-top: none;
        }
    }
}

.my-account-section {
    .product-recommendation {
        margin: 0;

        &-calloutmsg {
            @include get-font('compact');
            font-weight: 500;
            margin-bottom: 2.5rem;
            padding: 0;
        }

        .product {
            margin-bottom: 0;
        }
    }
}

// Card View Mobile Layout
@media screen and (max-width: $tablet-width) {
    .product-recommendation-grid {
        &.card-view {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
    
            .product {
                border: none;
                border-bottom: $card-view-product__border;
                padding-top: $card-view-product__padding-top;
            }
    
            .product:nth-child(2n+1) {
                padding-right: $base-padding;
                border-right: $card-view-product__border;
            }
    
            .product:nth-child(2n+2) {
                padding-left: $base-padding;
            }

            .product:last-child {
                &.odd {
                    display: none;
                }
            }
        }
    }

    .portrait-view {
        .product-recommendation-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
    
            .product {
                border: none;
                padding-top: $card-view-product__padding-top;
            }
    
            .product:nth-child(2n+1) {
                padding-right: $base-padding;
            }
    
            .product:nth-child(2n+2) {
                padding-left: $base-padding;
            }

            .product:last-child {
                &.odd {
                    display: none;
                }
            }
        }
    }
}
