// =============================================================================
//
//  Header Menu Navigation Medium Large
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================
// stylelint-disable selector-class-pattern
// stylelint-disable selector-max-compound-selectors
// stylelint-disable max-nesting-depth

@media screen and (min-width: $desktop-width-x-large) and (max-width: $desktop-width-large) {
    .menu {
        &-main {
            a {
                font-size: $header-navigation-category-link__font-size-small--medium-large;
            }
        }

        .menu-level-1-wrapper {
            .menu-category-level-1,
            .menu-pages-list {
                > li {
                    padding-right: 0;
    
                    a {
                        padding: 0 0.7 * $base-padding;
                    }
                }
            }
        }



        &-pages-list {
            > .menu-page-item:first-child {
                > .menu-page-link {
                    padding-right: $header-elements-base__padding--medium-large;
                    font-size: $header-navigation-category-link__font-size-small--medium-large;

                    &::before {
                        width: calc(#{$header-elements-full__width} - #{$header-elements-base__padding--medium-large});
                    }
                }
            }

            > .menu-page-item {
                .menu-page-link {
                    padding: 0 $header-elements-base__padding--medium-large;
                }
            }
        }

        &-category-level-1 {
            > li {
                > a {
                    font-size: $header-navigation-category-link__font-size--medium-large;
                }
            }
        }
    }
}


@media screen and (min-width: $desktop-width-x-large) and (max-width: $desktop-width-large) {
    .menu-category-level-1 > li > a {
        @include get-font('xsmall');
        padding: 0 $base-padding;
    }

    .menu-pages-list > .menu-page-item .menu-page-link,
    .menu-pages-list > .menu-page-item:first-child > .menu-page-link {
        @include get-font('xsmall');
        padding: 0 $base-padding * 0.8;
    }
}

@media screen and (min-width: $desktop-width-large) and (max-width: $desktop-width-huge) {
    .menu-category-level-1 > li > a {
        @include get-font('xsmall');
        padding: 0 $base-padding;
    }

    .menu-pages-list > .menu-page-item .menu-page-link,
    .menu-pages-list > .menu-page-item:first-child > .menu-page-link {
        @include get-font('xsmall');
        padding: 0 $base-padding;
    }
}
