// =============================================================================
//
//  404 Page
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Marko Bojovic <marko@improove.se>
//
//==============================================================================


@media screen and (min-width: $desktop-width) {
    .error-page {
        &-wrapper {
            margin-top: $error-page-wrapper__margin-top;
        }

        .page-title {
            @include get-font('enormous-gigantic');
        }

        .page-text-content {
            @include get-font('compact');
        }
    }

    .error-product-recommendations {
        .product-recommendation-grid {
            > .product:first-child {
                border-top: 0;
            }
        }

        .product-recommendation {
            padding-top: $error-product-recommendations__padding-top;
            border-top: $error-product-recommendations__border-top;
        }    
    }
}
