// =============================================================================
//
//  Dropdown Variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$dropdown-container__height: 5rem;

$dropdown__border: $black-two solid $border;
$dropdown__background-color: $white;

$dropdown-header-title__padding: ($base-padding * 0.55) ($base-padding * 4.4) ($base-padding * 0.55) 0;
$dropdown-header-title__margin: 0 ($base-margin * 1.5);
$dropdown-header-title__color: $greyish-brown;
$dropdown-header-title__line-height: 2.18;

$dropdown-header-title-after__padding-left: $base-padding * 1.3;
$dropdown-header-title-after__border-left: $brown-grey-two solid $border;
$dropdown-header-title-after-not-active__border-left: solid $border rgba($black-two, 0.2);
$dropdown-header-title-after__font-size: 1.9rem;
$dropdown-header-title-after__line-height: 1.58;

$dropdown-header-title-active__border-bottom: $brown-grey-two solid $border;

$dropdown-content-active__padding: ($base-padding * 1.1) 0 ($base-padding * 2.3) 0;
$dropdown-content-active__background-color: $white;

$dropdown-content-item-active__padding-right: ($base-padding * 1.5);
$dropdown-content-item-active__padding-left: ($base-padding * 1.5);
