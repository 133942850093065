// =============================================================================
//
//  Header Main
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// Wish List Header Icon Animation

@keyframes fadeOutIn {
    0% { 
        opacity: 1; 
    }

    50% { 
        opacity: 0; 
    }

    100% { 
        opacity: 1; 
    }
}


// Header Main

.header {
    &.fixed {
        position: fixed;
        z-index: 101;
        top: 0;
        left: 0;
        width: $header-elements-full__width;
        background-color: $header__background-color;
        transition: $header__transition;
    }

    &.scroll-down {
        top: -($header-main__height + $header-top__height + $header-search-form__height);
    }

    &-sticky-logo {
        display: none;
    }

    &-main {
        @include flexbox();
        @include align-items(center);
        position: relative;
        height: $header-main__height;
        background-color: $header-main__background-color;
    }

    &-main-container {
        display: block;
        padding: $header-main-container__padding;
    }

    &-menu-icons {
        @include flexbox();
        @include align-items(center);
        width: 100%;
        margin-left: 0;
        color: $header-main-menu-icons__color;
    }

    &-logo-small {
        width: $logo-small__width;
    }

    &-logo-big {
        width: $logo-big__width;
        height: $logo-big__height;
        margin-right: auto;
    }

    &-logo-image {
        max-width: $header-elements-full__width;
        max-height: $logo-small__height;
        backface-visibility: hidden;
        object-fit: contain;
    }

    &-hamburger-menu-icon {
        margin-right: $header-main-icons-side__margin;
        font-size: $header-main-hamburger-icon__font-size;

        .icon-menu {
            height: $header-main-hamburger-icon__height;
        }
    }

    &-user-icon {
        position: relative;
        height: $header-main-user-icon__height;
        margin-left: auto;

        .icon-nowishlist {
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            position: absolute;
            top: $header-main-wishlist-icon__top;
            left: $header-main-wishlist-icon__left;
            width: $header-main-wishlist-icon__width;
            height: $header-main-wishlist-icon__height;
            border-radius: 50%;
            background-color: $header-main-wishlist-icon__background-color;
            color: $header-main-wishlist-icon__color;
            font-size: $header-main-wishlist-icon__font-size;
            line-height: $header-main-wishlist-icon__line-height;
    
            &.fade-out-in {
                animation: fadeOutIn 0.3s ease;
            }
    
            &.fade-out-hide {
                opacity: 0;
                transition: opacity 0.3s ease;
            }
    
            &::before {
                line-height: $header-main-wishlist-icon__line-height;
            }
        }
    }

    &-login-link {
        color: $header-main-menu-icons__color;
        font-size: $header-main-login-icon__font-size;
        text-decoration: none;
    }

    &-cart-icon {
        @include flexbox();
        height: $header-main-cart-icon__height;
        margin-left: $header-cart-icon-side__margin-left;
        font-size: $header-main-cart-icon__font-size;
    }

    &-login-link,
    &-cart-icon {
        transition: $header-links__transition;

        &:hover {
            color: $header-main-icons-hover__color;
            cursor: pointer;
        }
    }

    &-container-search {
        @include flexbox();
        @include align-items(center);
        width: $header-elements-full__width;
        height: $header-search__height;
        background-color: $header-search__background-color;

        .icon-search {
            position: $icon-search__position;
            top: 1.3rem;
            left: $icon-search__left;
            color: $off-black;
            font-size: $header-search-icon__font-size;
        }
    }

    &-site-search {
        position: relative;
        width: $header-elements-full__width;
    }

    &-search-form {
        position: relative;
        width: $header-elements-full__width;
        height: $header-search-form__height;

        input[type='search'] {
            @include get-font('normal');
            width: $header-search-input__width;
            height: $header-search__height;
            padding: 0;
            border: 0;
            border-bottom: $input__border-bottom;
            background-color: $header-search-input__background-color;
            text-align: $header-search-form__text-align;

            &::placeholder {
                color: $header-search-placeholder__color;
                font-size: $header-search-placeholder__font-size;
            }
        }
    }

    &-menu-container {
        background-color: $header-main-container__background-color;

        .wrapper {
            position: unset;
            padding: 0;
        }
    }
}
