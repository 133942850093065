// =============================================================================
//
//  404 Page
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Marko Bojovic <marko@improove.se>
//
//==============================================================================

$error-page-title-mobile__font-size: 2.6rem;
$error-page-wrapper-top__margin: ($base-margin * 2.5) auto 0 auto;
$error-page-content__margin-bottom: $base-padding * 4;
$error-page-wrapper__padding: 0 ($base-padding * 1.2);
$error-page-page-img__margin-top: $base-margin * 2.3;
$error-page-page-img__margin-bottom: $base-margin * 2.9;
$error-page-page-content__margin-top: $base-margin * 2;

$error-product-recommendations__margin-bottom: $base-margin * 10;
$error-product-recommendations-calloutmsg__padding-top: $base-padding * 1.2;
$error-product-recommendations-calloutmsg__border-top: $border solid $pale-liliac;

//  large

$error-product-recommendations__border-top: 0.1rem solid #dadada;
$error-product-recommendations__padding-top: $base-padding * 5;
$error-page-wrapper__margin-top: $base-margin * 7.6;
$error-page-title__font-size: 3.8rem;

