// =============================================================================
//
//  Klarna Upsell large screen
//
//  @version    0.0.1
//  @package    HSNG
//  @author     Felipe Rohde <FRO@impact.dk>
//
//==============================================================================


@include media-screen-lg {
    .klarna-upsell-widget {
        .klarna-upsell {
            &-container {
                padding: ($base-padding * 1.6);
            }

            &-listing-header {
                width: 100%;
                max-width: 164.7rem;

                &-title {
                    @include get-font('normal');
                    padding: 5.5rem 0;
                    margin: ($base-padding * 3) auto;
                    background: $very-light-pink;
                }

                strong {
                    @include get-font('enormous');
                }

                p {
                    @include get-font('normal');
                    max-width: 98rem;
                    margin: 30px auto;
                    font-weight: $normal-weight;
                }
            }

            &-container-wrapper {
                width: 100%;
                max-width: 164.7rem;
                border: $border * 10 solid $red;
            }

            &-listing.row {
                .embla {
                    padding: 1rem 8rem;
                }

                .embla__slide {
                    min-width: auto;
                    max-width: 33%;
                    flex: 0 0 33%;
                    padding: 0 ($base-padding * 1.5);
                }

                .embla__button {
                    display: block;
                }

                .embla__button__svg {
                    fill: $black;
                }

                .product-tile-name {
                    @include get-font('medium');
                    min-height: 4rem;
                    font-weight: $bold;
                }

                .price {
                    @include get-font('medium-big');
                    min-height: auto;
                    font-weight: $bold;
                }
            }

            &-listing-footer {
                @include get-font('normal');
                max-width: none;
                margin-bottom: 0;
                font-weight: $normal-weight;
            }

            &-toolbar {
                text-align: center;

                button {
                    @include get-font('almost-big');
                    max-width: 40rem;
                    padding: $button-klarna__padding--large;
                    font-weight: $bold;
                }
            }

        }

        .embla__dots {
            display: none;
        }
    }

}
