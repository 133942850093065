
// =============================================================================
//
//  @version    0.0.1
//  @package    Gymgrossisten
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

// stylelint-disable selector-class-pattern
// stylelint-disable selector-max-compound-selectors

@import '../settings';
@import '../mixins/flex';
@import '../mixins/media-queries';
@import '../typography/mixins';

.gamifiera-wrapper {
    margin-bottom: 2rem;

    .gamifiera-profile-card {
        margin-bottom: 2rem;
    }

}

.gamifiera-tutorial {
    .border-bottom {
        border-bottom: $border solid $very-light-pink;
    }

    .padding-bottom {
        padding-bottom: $base-padding * 3;
    }

    .padding-top {
        padding-top: $base-padding * 3;
    }

    &-item {
        @include flexbox();
        @include flex-wrap(wrap);

        &:first-child {
            border-top: $border solid $very-light-pink;
        }

        .gamifiera-tutorial-item-half {
            width: 100%;
            padding: $base-padding * 3 0;

            &-image {
                margin-bottom: $base-margin * 3;
            }

            &.padd0 {
                padding-bottom: 0;
                border-bottom: 0;
            }
        }

        h2 {
            @include get-font('large');
            margin-bottom: $base-margin * 3;
            font-weight: $bolder;
            letter-spacing: normal;
        }

        p {
            @include get-font('xsmall');
            margin-bottom: $base-margin * 3;
        }

        .gamifiera-link {
            @include get-font('small');
            display: inline-block;
            width: 100%;
            padding: $base-padding $base-padding * 1.5;
            border: $border solid $black;
            background: $white;
            color: $black;
            font-weight: $bolder;
            text-align: center;
            text-decoration: none;
            
            &:hover,
            &:active,
            &:focus {
                color: $black;
                text-decoration: none;
            }
        }

        .button {
            @include get-font('small');
            display: inline-block;
            width: 100%;
            padding: $base-padding  $base-padding * 1.5;
            text-align: center;

            &:hover,
            &:active,
            &:focus {
                color: #ff0;
                text-decoration: none;
            }
        }

        &-image {
            @include flexbox();
            margin-left: 0;

            img {
                &:first-child {
                    margin-right: 1.2rem;
                }
            }
        }
    }

}

.account-container {
    .collapsible-header > .title {
        &.flex-title {
            @include flexbox();
            @include align-items(center);

            .gmf-my-avatar-thumbnail {
                width: 3rem;
                margin-right: $base-margin;
            }
        }
    }

    .gmf-select {
        width: 21.8rem !important;
        background: url('../images/arrow-dropdown.jpg') 15.5rem center #fff no-repeat !important;
    }
}

.show-review-tab {
    cursor: pointer;
}

.item {
    &.reviews {
        .gmf-product-rating {
            display: inline-block;

            .gmf-product-rating-label {
                @include get-font('small');
                color: $black;
            }

            .gmf-rating {
                display: none;
            }
        }
    }
}

.gmf-leaderboard-section select,
.gmf-select {
    width: 20.8rem !important;
    height: 3.9rem !important;
    padding: 0 1.5rem !important;
    border: $border solid $black !important;
    background: url('../images/arrow-dropdown.jpg') 14.5rem center #fff no-repeat !important;
    font-size: 1.8rem !important;

    &:focus,
    &:hover,
    &:active {
        box-shadow: none;
    }
}

