// =============================================================================
//
//  Breadcrumbs
//
//  @version    0.0.1
//  @package    HSNG
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

.breadcrumb {
    .breadcrumb-item {
        text-decoration: none;
    }

    &-wrapper {
        padding: $base-padding * 2.5 0 $base-padding * 2 0;
    }
}
