// =============================================================================
//
//  Details Desktop
//
//  @version    0.0.1
//  @package    HSNG -> Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .price-runner-badge {
        position: relative;
        top: 0.3rem;
        margin-right: auto;
        margin-left: $base-margin * 1.5;
    }

    .product-availability {
        .availability-msg {
            .item {
                &::before {
                    width: $product-availability-msg-item-before__width--large;
                    height: $product-availability-msg-item-before__height--large;
                    margin-right: $product-availability-msg-item-before__margin-right--large;
                }

                &.in-stock {
                    @include get-font('compact');
                }
            }
        }
    }

    .product-is-eligible-for-pledge-wrapper {
        margin-top: $base-margin;
    }

    .price-per-kg-value {
        @include get-font('almost-big');
    }

    .product-usps {
        &-item {
            &-img {
                max-width: $product-usps-item-img__max-width--large;
                max-height: $product-usps-item-img__max-height--large;
            }
        }

        &-text {
            @include get-font('xsmall');
        }
    }

    .product-promotion-boxes {
        @include get-font('compact');
        height: $product-promotion-boxes__height--large;

        .promotions {
            .promotion {
                @include get-font('medium-big');
                min-width: $product-promotion-boxes-promotions-promotion__min-width--large;
                padding: $product-promotion-boxes-promotions-promotion__padding--large;
            }
        }
    }

    .go-back {
        @include flexbox;
        @include align-items(center);
        padding: $go-back__padding--large;
        color: $go-back__color-large;
        font-family: $roboto-regular;
        text-decoration: none;

        &-link {
            color: $go-back__color-large;
            text-decoration: none;
        }

        &-icon {
            position: $go-back-icon__position;
            top: $go-back-icon__top;
            padding-right: $go-back-icon__padding-right--large;
            color: $go-back__color-large;
            cursor: pointer;
            font-size: $go-back-icon__font-size--large;
        }

        &-text {
            @include get-font('xsmall');
            position: $go-back-text__position;
            top: $go-back-text__top;
            cursor: pointer;

            &-parent-category {
                @include get-font('xsmall');
                color: $black-two;
                text-decoration: none;
            }
        }
    }

    .product-top-content {
        @include flexbox;
        @include flex-flow(row nowrap);
        @include justify-content(space-between);
    }

    .product-images {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        flex-basis: $product-images__flex-basis--large;
        margin-top: 0;
        margin-bottom: 0;

        &.desktop {
            position: relative;
            z-index: 0;
            opacity: 1;
            visibility: visible;

            .slick-arrow {
                @include get-font('almost-big');
            }
        }

        &.mobile {
            position: absolute;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
        }

        .promotions {
            top: $promotions__top--large;
        }

        .promotions-right {
            .promotion {
                @include get-font('medium-big');
            }

        }

        .promotion {
            @include get-font('xsmall');
            min-width: 15.5rem;
            padding: $base-padding * 0.8 0;

            > span {
                padding: $promotions-promotion-span__padding--large;
            }
        }

        .product-image {
            width: $product-image__width--large;
        }
    }

    .product-rating {
        .gmf-product-rating {
            @include flex-direction (column-reverse);
        }
    }

    .product-swatch-images {
        @include flexbox;
        @include flex-flow(row wrap);
        position: relative;

        .swatch {
            position: relative;
            margin: $swatch__margin--large;
            background-color: $swatch__background-color--large;
            cursor: pointer;

            &-image {
                height: $swatch-image__height--large;
            }

            &-rectangle {
                display: none;
            }

            &.active {
                .swatch-rectangle {
                    position: absolute;
                    bottom: 0;
                    display: block;
                    width: 100%;
                    height: $swatch-rectangle-active__height--large;
                    background-color: $swatch-rectangle-active__background-color--large;
                }
            }
        }
    }

    .product-detail {
        @include flexbox;
        @include flex-flow(column nowrap);
        flex-basis: $product-detail__flex-basis--large;

        .breadcrumb {
            margin-top: 0;
        }
    }

    .breadcrumb {
        order: 0;
        margin-top: 0;

        &-item {
            @include get-font('xsmall');
        }
    }

    .product-name {
        order: 1;
        margin-top: $product-name__margin-top--large;

        h1 {
            @include get-font('enormous-gigantic');
        }
    }

    .brand-link {
        order: 2;
    }

    .prices-and-availability {
        @include align-items(baseline);
        order: 3;

        .price {
            @include flexbox;
            @include flex-flow(row nowrap);
            @include flex-direction(column);
            @include align-items(baseline);
            width: 100%;

            .price-adjusted {
                @include get-font('extra-gigantic');
                margin-right: $price-adjusted__margin-right--large;
            }

            .price-non-adjusted {
                @include get-font('almost-big');
            }

            .price-sales {
                @include get-font('gigantic');
            }

            .price-ref {
                @include get-font('small');
            }
        }
    }

    .price-ref__stmt:nth-child(2) {
        margin-right: auto;
        margin-left: auto;
    }

    .product-availability {
        position: static;
    }

    .hr-line-one {
        order: 4;
        margin-top: $hr-line-one__margin-top--large;
    }

    .product-info-section {
        order: 11;
        padding-top: $product-info-section__padding-top--large;
        border-top: $product-info-section__border-top--large;
    }

    .product-short-description {
        @include get-font('compact');
        line-height: $p__line-height;

        ul {
            padding-left: $product-short-description-ul__padding-left--large;
        }

        ul > li {
            @include get-font('compact');
            line-height: $product-short-description-ul-li__line-height--large;

            &::before {
                margin-top: $product-short-description-ul-li-before__margin-top--large;
                margin-left: $product-short-description-ul-li-before__margin-left--large;
            }
        }
    }

    .read-more {
        margin: $read-more__margin--large;
    }

    .hr-line-two {
        order: 6;
        margin-top: $hr-line-two__margin-top--large;
    }

    .product-outofstock-popup__container {
        order: 7;

        .product-outofstock-popup {
            top: 12rem;

            &-wrapper {
                padding: $product-outofstock-popup__padding--large;
            }

            &-question {
                @include get-font('medium');
                font-weight: $bolder;;
            }

            &-email-form {
                &-label {
                    @include get-font('xsmall');
                    display: none;
                }

                &-input {
                    @include get-font('normal');
                }
            }
        }
    }

    .product-variations {
        order: 8;
        margin-top: $product-variations__margin-top--large;
        max-width: 59.2rem;

        .product-variation-info {
            @include flexbox;
            @include flex-flow(row nowrap);
            @include justify-content(space-between);
            @include align-items(flex-end);

            .product-variation-info-label {
                @include get-font('compact');
                margin-top: $product-variations__margin-top--large;
            }

            &:first-of-type {
                .product-variation-info-label {
                    margin-top: 0;
                }
            }
        }
    }

    .quantity-and-add-to-cart {
        order: 9;
        margin-top: $quantity-and-add-to-cart__margin-top--large;

        .cart-and-ipay {
            .add-to-cart,
            .notify-me {
                @include get-font('large');
                max-height: $cart-and-ipay-add-to-cart__max-height--large;
                padding: $cart-and-ipay-add-to-cart__padding--large;
            }
        }

        .quantity-form .quantity {
            padding: 0;
            margin-top: $base-margin * 0.7;
        }
    }

    .product-klarna-placement {
        min-height: 5rem;
        order: 9;
        margin-top: $base-margin * 2;
    }

    .product-disclaimers {
        order: 10;

        .product-outofstock {
            &-circle {
                @include get-font('normal');

                width: $product-outofstock-circle__width--large;
                min-width: $product-outofstock-circle__min-width--large;
                height: $product-outofstock-circle__height--large;
                line-height: $product-outofstock-circle__line-height--large;
            }

            &-text {
                @include get-font('compact');
            }
        }
    }

    .promotion-details {
        order: 10;

        .promotion-box {
            &-circle {
                @include get-font('normal');
    
                width: $promotion-box-circle__width--large;
                min-width: $promotion-box-circle__min-width--large;
                height: $promotion-box-circle__height--large;
                line-height: $promotion-box-circle__line-height--large;
            }
    
            &-text {
                @include get-font('compact');
            }
    
            &-tiles {
                @include justify-content(flex-start);
            }
        }
    }

    .quantum {
        order: 7;

        .promotion-box-tiles {
            @include justify-content(flex-start);
            @include align-items(stretch);
        }
    }

    .product-usps-container {
        order: 11;

        .product-usps {
            &-item {
                &:not(:last-child) {
                    padding-right: $product-usps-item-not-last-child__padding-right--large;
                }

                &:not(:first-child) {
                    padding-left: $product-usps-item-not-first-child__padding-left--large;
                }
            }
        }
    }

    .hr-line-three {
        margin-top: $hr-line-three__margin-top--large;
    }

    .hr-line-four {
        display: none;
    }

    .product-tabs {
        .item {
            @include get-font('medium');
        }

        .columns {
            @include get-font('small');
            column-count: 2;
            column-gap: 6%;
            line-height: $columns__line-height--large;

            p {
                line-height: $columns__line-height--large;
            }

            &:first-child {
                margin-top: 0;
            }

            &.active {
                display: block;
            }

            /* stylelint-disable property-no-vendor-prefix */

            > * {
                overflow: hidden; /* Fix for firefox and IE 10-11  */
                padding-right: $columns__padding-right--large;
                break-inside: avoid; /* IE 10+ */
                break-inside: avoid-column;
                -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
                page-break-inside: avoid; /* Firefox */

                &:first-child {
                    margin-top: 0;
                }
            }

            table {
                width: $columns-table__width--large;
            }
        }

        .master-product-long-descriptions,
        .master-product-nutrition-and-ingredients {
            width: 75%;
        }

        .tabs-header {
            > .item {
                padding: 0;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    .product-short-description {
        .view-full-description {
            margin-top: $base-margin * 1.3;
        }
    }

    // Product soblings desktop style

    .product-siblings {
        position: absolute;
        right: 0;
        left: 0;
        width: auto;
        padding: 0;
        margin: 0;
        overflow-x: scroll;
        overflow-y: hidden;

        &-wrapper {
            width: 100%;
            height: 18rem;
            order: 7;
            border-bottom: $product-info-section__border-top--large;
        }

        &-arrow-wrapper {
            &.arrow-left {
                left: -25px;
            }

            &.arrow-right {
                right: -25px;
            }
        }

        .product-tile {
            border: 0;

            &-promotions-right {
                display: none;
            }
        }
    }
}

.variation-dropdown,
.set-product-dropdown {
    .dropdown-content.variations {
        .item.variation-item,
        .item.set-product-item {
            padding-top: 1.7rem;
            padding-bottom: 1.7rem;
        }

        .variation-span {
            @include get-font('small');
        }
    }
}

.dropdown.active .dropdown-content {
    padding: 0;
}

@media screen and (min-width: $desktop-width) and  (max-width: 1200px) {
    .prices-and-availability .price .price-adjusted {
        @include get-font('enormous');
    }

    .prices {
        @include flexbox;
        @include align-items(baseline);
        width: 100%;
    }

    .prices-and-availability .prices {
        display: block;
        width: 100%;
    }

    .product-is-eligible-for-pledge-wrapper {
        margin-bottom: $base-margin;
        margin-left: 0;
    }

    .product-images.desktop {
        max-width: 27rem;
    }
}

@media screen and (min-width: $desktop-width) and  (max-width: $desktop-width-huge) {
    .product-variations {
        max-width: 54rem;
    }
}
