// =============================================================================
//
//  Quick View
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// Quickview Modal Popup

$quickview-modal__z-index: 1050;
$quickview-modal-full_width: 100%;
$quickview-modal-full_height: 100%;
$quickview-modal-full-viewport_width: 100vw;
$quickview-modal-full-viewport_height: 100vh;
$quickview-modal-backdrop__z-index: 1040;
$quickview-modal-inner__padding: 0 ($base-padding * 3) ($base-padding * 3);
$quickview-modal-dialog__margin: 10rem auto auto;
$quickview-modal-content_background-color: $white;
$quickview-modal-backdrop__background-color: $black;
$quickview-modal-backdrop__opacity: .5;
$quickview-modal-header__padding: $base-padding $base-padding 0 0;
$quickview-modal-dialog__max-width: 100rem;
$quickview-close-button__background-color: transparent;
$quickview-close-button__color: $black;
$quickview-close-button__font-size: 2rem;

